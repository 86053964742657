import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  appSettingsReducer,
  menuReducer,
  accountReducer,
  addressReducer,
  cityReducer,
  promotionReducer,
  orderReducer,
  restaurantReducer,
  pagesReducer,
  bookingReducer,
  socialMediaReducer,
  blogReducer,
  brandReducer,
} from 'store/reducers';

const orderPersistConfig = {
  key: 'order',
  storage,
  version: 1,
  whitelist: ['order', 'basket'],
};

const apiPersistConfig = {
  key: 'api_key',
  storage,
  whitelist: ['api_key'],
};

const brandPersistConfig = {
  key: 'brands',
  storage,
  whitelist: ['brand_id'],
};

const cityPersistConfig = {
  key: 'city',
  storage,
  whitelist: ['city_id'],
};

const addressPersistConfig = {
  key: 'address',
  storage,
  whitelist: ['defaultAddressId'],
};

const rootReducer = combineReducers({
  appSettings: appSettingsReducer,
  menu: menuReducer,
  account: persistReducer(apiPersistConfig, accountReducer),
  addresses: persistReducer(addressPersistConfig, addressReducer),
  cities: persistReducer(cityPersistConfig, cityReducer),
  promotions: promotionReducer,
  order: persistReducer(orderPersistConfig, orderReducer),
  restaurants: restaurantReducer,
  pages: pagesReducer,
  booking: bookingReducer,
  socialMedia: socialMediaReducer,
  blog: blogReducer,
  brand: persistReducer(brandPersistConfig, brandReducer),
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
