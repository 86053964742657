import React, { FC, memo } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import { Category, Nav } from 'components/menu';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useMenu } from 'hooks/menu';
import { useScrollToBlock } from 'hooks';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { selectMenuIsLoading } from 'store/selectors/menuSelectors';

const Menu: FC = () => {
  useScrollToBlock();
  const isLoading = useAppSelector(selectMenuIsLoading);
  const {
    opacity,
    activeCategory,
    menuWithOffers,
    categoryRefs,
    rootMargin,
    handleScrollTo,
    handleSetActiveCategory,
  } = useMenu();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {t('menu')}
        </title>
      </Helmet>

      <Box sx={{ minHeight: '90vh' }}>
        <Nav activeCategory={activeCategory} handleScrollTo={handleScrollTo} />
        <Box sx={{ padding: { xs: '60px 0', sm: '80px 0' } }}>
          <Container>
            {isLoading ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Box sx={{ opacity: `${opacity}`, transition: 'opacity 0.3s' }}>
                {menuWithOffers.map((category) => (
                  <Category
                    key={category.id}
                    activeCategory={activeCategory}
                    handleSetActiveCategory={handleSetActiveCategory}
                    categoryRefs={categoryRefs}
                    items={category.items}
                    categoryId={category.id}
                    categoryName={category.name}
                    rootMargin={rootMargin}
                  />
                ))}
              </Box>
            )}
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default memo(Menu);
