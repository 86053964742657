import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Modal, Drawer } from 'components/modals/BrandsModal';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectBrands } from 'store/selectors/brandsSelectors';

interface BrandsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BrandsModal: FC<BrandsModalProps> = ({ isOpen, onClose }) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });
  const brands = useAppSelector(selectBrands);

  return (
    <>
      {isDrawer ? (
        <Drawer isOpen={isOpen} onClose={onClose} brands={brands} />
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} brands={brands} />
      )}
    </>
  );
};

export default BrandsModal;
