import React, { FC } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { Rating, SelectField, Switch } from 'components/generic';
import { AuthModal } from 'components/modals';
import { useReviews } from 'hooks/reviews';

const Reviews: FC = () => {
  const {
    t,
    control,
    isVisiting,
    isAccountLoading,
    handleChange,
    authModal,
    handleAuthModalClose,
    handleButton,
    orders,
    restaurantList,
  } = useReviews();

  return (
    <>
      <Stack sx={{ alignItems: 'center' }} direction="column" spacing={2}>
        <Typography
          sx={{
            fontSize: '30px',
            lineHeight: '30px',
            fontWeight: '400',
            textAlign: 'center',
          }}
        >
          {t('review_page.your_opinion')}
        </Typography>
        <Typography
          sx={{
            fontSize: '20px',
            lineHeight: '23px',
            fontWeight: '300',
            marginBottom: '23px',
            textAlign: 'center',
            maxWidth: '670px',
          }}
        >
          {t('review_page.preview_text')}
        </Typography>

        <Stack
          sx={{ minWidth: { xs: '100%', sm: '430px' } }}
          direction="column"
          spacing={3}
        >
          {orders.length > 0 && restaurantList.length > 0 && (
            <Switch
              firstValue={t('review_page.about_delivery')}
              secondValue={t('review_page.about_visit')}
              value={isVisiting}
              handleChange={handleChange}
            />
          )}

          {!isVisiting && orders.length > 0 && (
            <SelectField
              control={control}
              name="order_id"
              label={t('review_page.order_number')}
              size="small"
              required
              selectItem={orders}
              defaultValue=""
            />
          )}

          {isVisiting && restaurantList.length > 0 && (
            <SelectField
              control={control}
              name="rest_id"
              label="Ресторан"
              size="small"
              required
              selectItem={restaurantList}
              defaultValue=""
            />
          )}

          <Stack
            sx={{ alignItems: 'center', width: '100%' }}
            direction="column"
            spacing={1}
          >
            <Typography
              sx={{ fontSize: '14px', lineHeight: '17px', color: '#888888' }}
              component="legend"
            >
              {t('review_page.rate_our_kitchen')}
            </Typography>
            <Rating
              control={control}
              name="stars_kitchen"
              size="large"
              required
            />
          </Stack>
          <Stack
            sx={{ alignItems: 'center', width: '100%' }}
            direction="column"
            spacing={1}
          >
            <Typography
              sx={{ fontSize: '14px', lineHeight: '17px', color: '#888888' }}
              component="legend"
            >
              {t('review_page.rate_our_service')}
            </Typography>
            <Rating
              control={control}
              name="stars_service"
              size="large"
              required
            />
          </Stack>
          <Box sx={{ textAlign: 'center' }}>
            {isAccountLoading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                sx={{
                  fontSize: '20px',
                  lineHeight: '23px',
                  height: '50px',
                  color: 'primary.contrastText',
                }}
                onClick={handleButton}
              >
                {t('review_page.next')}
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>

      <AuthModal isOpen={authModal} onClose={handleAuthModalClose} />
    </>
  );
};

export default Reviews;
