import React, { FC, useCallback, useState } from 'react';
import { Modal, Drawer } from 'components/modals/AuthModal';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from 'hooks/auth';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { selectApiKey } from 'store/selectors/accountSelectors';
import { updateAccountData } from 'store/reducers/AccountSlice/ActionCreators';
import { accountSlice } from 'store/reducers/AccountSlice/AccountSlice';
import { AppSettingsData } from 'types/AppSettings';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AuthModal: FC<AuthModalProps> = ({ isOpen, onClose }) => {
  const isDrawer = useMediaQuery({ maxWidth: 767 });
  const dispatch = useAppDispatch();
  const { getAuthCode } = useAuth();
  const methods = useForm();
  const { watch, setError, reset } = methods;
  const loginValue = watch('login');
  const nameValue = watch('first_name');
  const [step, setStep] = useState(1);
  const { clearAuthResponse } = accountSlice.actions;
  const { AUTH_SERVICE_TYPE } = useAppSelector<AppSettingsData>(
    selectAppSettingsData
  );
  const api_key = useAppSelector(selectApiKey);

  const nextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const prevStep = useCallback(() => {
    dispatch(clearAuthResponse());
    setStep(step - 1);
  }, [step]);

  const onSubmit = () => {
    if (step === 1) {
      if (AUTH_SERVICE_TYPE === 1) {
        getAuthCode(loginValue.slice(1), AUTH_SERVICE_TYPE);
      }
      if (AUTH_SERVICE_TYPE === 0) {
        getAuthCode(loginValue, AUTH_SERVICE_TYPE);
      }
    }
    if (step === 3) {
      dispatch(updateAccountData({ api_key, body: { first_name: nameValue } }))
        .unwrap()
        .then((res) => {
          if (res.success) {
            onClose();
            setStep(1);
          } else {
            setError('first_name', {
              type: 'server',
              message: 'some error',
            });
          }
        });
    }
  };

  const handleClose = useCallback(() => {
    onClose();
    reset();
    setTimeout(() => {
      setStep(1);
    }, 200);
  }, []);

  return (
    <>
      {isDrawer ? (
        <Drawer
          step={step}
          methods={methods}
          isOpen={isOpen}
          onClose={handleClose}
          onSubmit={onSubmit}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      ) : (
        <Modal
          step={step}
          methods={methods}
          isOpen={isOpen}
          onClose={handleClose}
          onSubmit={onSubmit}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
    </>
  );
};

export default AuthModal;
