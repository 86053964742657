import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectMenuProduct,
  selectCalculateProductPrice,
} from 'store/selectors/menuSelectors';
import { useForm } from 'react-hook-form';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { useCalculateProduct } from 'hooks/menu';
import useRandomId from 'hooks/useRandomId';
import useSrcImage from 'hooks/useSrcImage';

const useProductModal = (
  onClose: () => void,
  productId: number,
  categoryId: number
) => {
  const dispatch = useAppDispatch();
  const { addProduct } = orderSlice.actions;
  const {
    name,
    options,
    group_modifiers,
    ingredients,
    cooking_options,
    src,
    additional_images,
    description,
    extra_description,
    order_by,
    is_only_pickup,
  } = useAppSelector(selectMenuProduct(productId, categoryId));
  const methods = useForm();
  const { watch, reset, setValue } = methods;
  const formValues = watch();
  const randomId = useRandomId(order_by);
  const image = useSrcImage(src);
  const optionValue = watch('option');

  const { totalPriceKop, totalPrice } = useAppSelector(
    selectCalculateProductPrice(formValues, productId, categoryId)
  );

  const calculatedProduct = useCalculateProduct(
    formValues,
    totalPriceKop,
    randomId,
    src,
    name,
    group_modifiers,
    options,
    ingredients,
    description,
    is_only_pickup,
    cooking_options
  );

  const onSubmit = () => {
    dispatch(addProduct(calculatedProduct));
    reset();
    onClose();
  };

  const isCombo = useMemo(() => {
    return (
      options.length <= 1 &&
      ingredients.length <= 0 &&
      cooking_options.length <= 0 &&
      group_modifiers.length > 1
    );
  }, [options, ingredients, cooking_options, group_modifiers]);

  const optionDesc = useMemo(() => {
    if (options.length > 1) {
      return options.find((item) => item.id === Number(optionValue))
        ?.description;
    }

    return options[0].description;
  }, [options, optionValue]);

  // Set default values
  useEffect(() => {
    group_modifiers.forEach((modifier) => {
      setValue(`${modifier.id}`, modifier.modifiers[0]?.id.toString());
    });
    setValue('ingredients', []);
    setValue('option', options[0].id.toString());
  }, [group_modifiers, options]);

  return {
    name,
    options,
    group_modifiers,
    ingredients,
    cooking_options,
    image,
    additional_images,
    extra_description,
    methods,
    onSubmit,
    isCombo,
    description,
    calculatedDescription: calculatedProduct.description,
    totalPrice,
    optionDesc,
  };
};

export default useProductModal;
