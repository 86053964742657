import React, { FC, useEffect, useState, useCallback } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { NavMobile } from 'components/generic/Header';
import { MenuTab } from 'types/MenuTabs';
import { useTranslation } from 'react-i18next';
import { useMenuTabs } from 'hooks/menu';
import { BrandsModal } from 'components/modals';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsBrands } from 'store/selectors/brandsSelectors';

const Nav: FC = () => {
  const location = useLocation();
  const menuTabs = useMenuTabs();
  const [value, setValue] = useState<string>(location.pathname);
  const { t } = useTranslation();
  const [brandsModal, setBrandsModal] = useState<boolean>(false);
  const isBrands = useAppSelector(selectIsBrands);

  const handleBrandsModalOpen = useCallback(() => {
    setBrandsModal(true);
  }, [brandsModal]);

  const handleBrandsModalClose = useCallback(() => {
    setBrandsModal(false);
  }, [brandsModal]);

  useEffect(() => {
    const isPath = menuTabs.some(
      (tab) => tab.path.slice(1) === location.pathname.split('/')[1]
    );

    if (isPath) {
      setValue(`/${location.pathname.split('/')[1]}`);
    } else {
      setValue(location.pathname);
    }
  }, [location]);

  return (
    <>
      <Box
        sx={{
          flexGrow: { xs: 0, md: 1 },
          height: { xs: '70px', md: '80px' },
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tabs
          sx={{
            height: '100%',
            '& .MuiTabs-flexContainer': {
              height: '100%',
              display: { xs: 'none', md: 'flex' },
            },
            '& .MuiTabs-indicator': {
              height: '3px',
            },
          }}
          value={value}
        >
          {isBrands && (
            <Tab
              sx={{
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: '300',
                color: 'text.primary',
              }}
              label="Изменить ресторан"
              onClick={handleBrandsModalOpen}
              component="div"
            />
          )}

          <Tab
            sx={{
              visibility: 'hidden',
              position: 'absolute',
              left: '-1000px',
              width: '1px',
              height: '1px',
              overflow: 'hidden',
            }}
            label="*"
            value={value}
            to={value}
            component={NavLink}
          />
          {menuTabs.map((tab: MenuTab) => (
            <Tab
              sx={{
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: '300',
                color: 'text.primary',
                transition: 'color 0.1s ease-in-out',
                padding: { xs: '0px', lg: '12px 16px' },
                textTransform: 'capitalize',
              }}
              label={t(tab.label)}
              component={NavLink}
              value={tab.path}
              to={tab.path}
              key={tab.path}
            />
          ))}
        </Tabs>
        <Box
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          <NavMobile menuTabs={menuTabs} />
        </Box>
      </Box>
      <BrandsModal isOpen={brandsModal} onClose={handleBrandsModalClose} />
    </>
  );
};

export default Nav;
