import React, { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TextField } from 'components/generic';
import { useTranslation } from 'react-i18next';

interface StepThreeProps {
  onClose: () => void;
}

const StepThree: FC<StepThreeProps> = ({ onClose }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography sx={{ marginBottom: '20px' }} variant="h4">
        {t("let's_get_acquainted")}
      </Typography>
      <Typography
        sx={{
          flexGrow: 1,
        }}
      >
        {t('auth_tip_7')}
      </Typography>
      <Box>
        <Box sx={{ marginBottom: '100px' }}>
          <TextField control={control} name="first_name" label={t('name')} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
          gap={2}
        >
          <Button
            sx={{
              padding: '10px 0',
              textTransform: 'inherit',
              fontSize: '18px',
              lineHeight: '21px',
              borderRadius: '6px',
              fontWeight: '300',
              color: 'text.primary',
              boxShadow: 'none',
            }}
            variant="text"
            color="inherit"
            onClick={onClose}
            fullWidth
          >
            {t('later')}
          </Button>

          <Button
            sx={{
              padding: '15px 0',
              textTransform: 'inherit',
              fontSize: '18px',
              lineHeight: '21px',
              borderRadius: '6px',
              fontWeight: '300',
              color: 'primary.contrastText',
              boxShadow: 'none',
            }}
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
          >
            {t('continue')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default StepThree;
