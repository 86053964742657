import React, { FC } from 'react';
import { Box, InputAdornment, TextField as MUITextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { checkPromoCode } from 'store/reducers/PromotionSlice/ActionCreators';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { SnackActions } from 'components/generic';
import { useSnackbar } from 'notistack';
import {
  selectPromotionsData,
  selectPromotionsWithPromocode,
} from 'store/selectors/promotionSelectors';

const Promocode: FC = () => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const { watch, reset } = methods;
  const code = watch('promocode');
  const { addPromotion } = orderSlice.actions;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const promoWithCode = useAppSelector(selectPromotionsWithPromocode);
  const promotions = useAppSelector(selectPromotionsData);

  const handleCheckCode = () => {
    dispatch(
      checkPromoCode({ api_key: '', platform: 2, promocode: code, brand_id: 0 })
    )
      .unwrap()
      .then((res) => {
        if (res.success && res.data.length > 0) {
          const promo = promoWithCode.find((item) => item.id === res.data[0]);
          const avalibalePromo = promotions.filter(
            (item) => item.id === res.data[0]
          );
          if (avalibalePromo.length > 0) {
            dispatch(
              addPromotion({
                action_id: promo?.id,
                item_option_id: promo?.promo_dishes[0].item_option,
              })
            );
            enqueueSnackbar(t('promo_code_successfully_applied'), {
              action: SnackActions,
              variant: 'success',
              autoHideDuration: 3000,
              preventDuplicate: true,
            });
            reset();
          } else {
            enqueueSnackbar(t('promo_code_not_found'), {
              action: SnackActions,
              variant: 'error',
              autoHideDuration: 3000,
              preventDuplicate: true,
            });
          }
        }
        if (
          !res.success ||
          res.reason === 'WRONG_PROMOCODE' ||
          res.data.length <= 0
        ) {
          enqueueSnackbar(t('promo_code_not_found'), {
            action: SnackActions,
            variant: 'error',
            autoHideDuration: 3000,
            preventDuplicate: true,
          });
        }
      });
  };

  return (
    <Box sx={{ padding: { xs: '0', md: '0 25px' } }}>
      <Controller
        name="promocode"
        control={methods.control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <MUITextField
            sx={{
              '& .MuiOutlinedInput-input': {
                padding: '13.5px 14px',
              },
              '& .MuiInputLabel-root': {
                color: '#BDBDBD',
                lineHeight: '20px',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'secondary.main',
              },
              '& .MuiInputLabel-shrink': {
                color: 'text.primary',
                backgroundColor: 'primary.main',
                padding: '0px 5px',
                left: '-5px',
                top: '2px',
                fontWeight: '600',
                fontSize: '13px',
                lineHeight: '20px',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                '& fieldset': {
                  borderColor: 'primary.main',
                },
                '&:hover fieldset': {
                  border: '1px solid',
                  borderColor: 'secondary.main',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid',
                  borderColor: 'secondary.main',
                },
              },
              '& .MuiFormHelperText-root': {
                position: 'absolute',
                top: '45px',
                left: '0',
              },
            }}
            placeholder={t('promocode')}
            hiddenLabel
            onChange={onChange}
            value={value || ''}
            size="medium"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ cursor: 'pointer' }}
                  onClick={handleCheckCode}
                >
                  <ArrowForwardIosIcon fontSize="small" color="primary" />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default Promocode;
