import React, { FC, useCallback } from 'react';
import {
  ClickAwayListener,
  Tooltip as MUITooltip,
  IconButton,
  Box,
} from '@mui/material';
import { InfoIcon } from 'components/icons';
import Parser from 'html-react-parser';

interface TooltioProps {
  title: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

const Tooltip: FC<TooltioProps> = ({ title, placement }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const handleToggleTooltip = useCallback(() => {
    setOpen(!open);
  }, [open]);

  if (!title) {
    return null;
  }

  return (
    <Box>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <MUITooltip
          PopperProps={{
            disablePortal: true,
          }}
          arrow
          placement={placement || 'left'}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={Parser(title)}
        >
          <IconButton onClick={handleToggleTooltip}>
            <InfoIcon fontSize="small" color="primary" />
          </IconButton>
        </MUITooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default Tooltip;
