import React, { FC } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import aboutBg from 'assets/images/about_bg.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import Parser from 'html-react-parser';

interface InfoProps {
  title: string;
  text: string;
  src: string;
}

const Info: FC<InfoProps> = ({ title, text, src }) => {
  const { BOOKING_TABLE } = useAppSelector(selectAppSettingsData);
  const { t } = useTranslation();

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          backgroundColor: 'secondary.main',
          opacity: '0.3',
          zIndex: '-1',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          zIndex: '-1',
        }}
      >
        <Box
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            opacity: '0.2',
          }}
          component="img"
          src={aboutBg}
        />
      </Box>
      <Box
        sx={{
          height: { xs: '250px', md: 'auto' },
          width: { xs: '100%', md: '50%' },
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          component="img"
          src={src}
        />
      </Box>
      <Container>
        <Box
          sx={{
            display: 'flex',
            padding: { xs: '30px 0', md: '70px 0' },
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box
            sx={{
              height: { xs: '250px', md: 'auto' },
              width: { xs: '100%', md: '50%' },
              marginBottom: { xs: '30px', md: '0' },
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              component="img"
              src={src}
            />
          </Box>
          <Box
            sx={{
              width: { xs: '100%', md: '50%' },
              padding: { xs: '0px', md: '0 60px' },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: { xs: '20px', sm: '30px' },
                lineHeight: { xs: '20px', sm: '30px' },
                color: 'secondary.main',
                marginBottom: { xs: '20px', sm: '30px' },
                fontWeight: '400',
              }}
              component="h4"
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '16px', sm: '20px' },
                lineHeight: { xs: '18px', sm: '23px' },
                fontWeight: '300',
                marginBottom: { xs: '24px', sm: '50px' },
              }}
              component="div"
            >
              {Parser(text)}
            </Typography>
            {BOOKING_TABLE && (
              <Button
                sx={{
                  fontSize: '20px',
                  fontWeight: '300',
                  color: 'secondary.main',
                  boxShadow: 'none',
                  backgroundColor: 'primary.main',
                  textTransform: 'inherit',
                  borderRadius: '6px',
                  width: { xs: '100%', sm: 'auto' },
                }}
                to="/reserve#book_a_table"
                component={Link}
                variant="contained"
              >
                {t('book_a_table')}
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Info;
