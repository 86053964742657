import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useSrcImage } from 'hooks';

interface ItemImageProps {
  src: string;
}

const ItemImage: FC<ItemImageProps> = ({ src }) => {
  const { ITEM_PLACEHOLDER_IMAGE } = useAppSelector(selectAppSettingsData);
  const image = useSrcImage(src);
  return (
    <>
      <Box
        sx={{
          height: { xs: '117px', sm: '110px' },
          width: { xs: '117px', sm: '110px' },
          minWidth: { xs: '117px', sm: '110px' },
          overflow: 'hidden',
          borderRadius: '0px',
        }}
      >
        {!image ? (
          <Box
            component="img"
            sx={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
            }}
            alt="product image"
            src={ITEM_PLACEHOLDER_IMAGE}
          />
        ) : (
          <Box
            component="img"
            sx={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
            }}
            alt="product image"
            src={image}
          />
        )}
      </Box>
    </>
  );
};

export default ItemImage;
