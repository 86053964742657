import React, { FC, useCallback } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { SubTitle, BackButton } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPrivacyPolicyPageData } from 'store/selectors/pageSelectors';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Parse from 'html-react-parser';

const PrivacyPolicy: FC = () => {
  const { text } = useAppSelector(selectPrivacyPolicyPageData);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
      <Container>
        <Box sx={{ marginBottom: '20px' }}>
          <BackButton handleBack={handleBack} />
        </Box>
        <Box sx={{ marginBottom: '30px' }}>
          <SubTitle>{t('privacy_policy')}</SubTitle>
        </Box>
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '18px' },
            lineHeight: { xs: '18px', sm: '22px' },
            fontWeight: '300',
          }}
          component="div"
        >
          {Parse(text)}
        </Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
