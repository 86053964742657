import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { BackDrawerButton, Portal, SearchField } from 'components/generic';
import { animated, useSpring } from 'react-spring';
import { useWindowDimensions } from 'hooks/menu';
import { useDisableBodyScroll, useDelayUnmount } from 'hooks';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import {
  selectCitiesList,
  selectCitiesIsLoading,
  selectCityId,
} from 'store/selectors/citySelectors';
import { CitiesList } from 'components/modals/SelectCityModal/Drawer';
import { useTranslation } from 'react-i18next';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const Drawer: FC<DrawerProps> = ({ isOpen, onClose }) => {
  useDisableBodyScroll(isOpen);
  const citiesList = useAppSelector(selectCitiesList);
  const isCitiesLoading = useAppSelector(selectCitiesIsLoading);
  const { LOGO_LIGHT } = useAppSelector(selectAppSettingsData);
  const [inputValue, setInputValue] = useState<string>('');
  const isMount = useDelayUnmount(isOpen, 500);
  const { pageHeight } = useWindowDimensions();
  const [filteredCities, setFilteredCities] = useState(citiesList);
  const { t } = useTranslation();
  const cityId = useAppSelector(selectCityId);

  useEffect(() => {
    const filtered = citiesList.filter((city) =>
      city.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredCities(filtered);
  }, [inputValue, citiesList]);

  const handleChangeValue = useCallback(
    (value: string) => {
      setInputValue(value);
    },
    [inputValue]
  );

  const [{ y }, api] = useSpring(() => ({
    y: pageHeight,
  }));

  const open = () => {
    api.start({
      y: 0,
      immediate: false,
    });
  };

  const close = () => {
    api.start({
      y: pageHeight,
      immediate: false,
    });
  };

  useEffect(() => {
    if (isOpen) {
      open();
    } else {
      close();
    }
  }, [isOpen]);

  return (
    <Portal>
      {isMount && (
        <Box>
          <animated.div
            style={{
              position: 'fixed',
              top: '0px',
              left: '0px',
              right: '0px',
              width: '100%',
              height: '150vh',
              background: '#ffffff',
              y,
            }}
          >
            {cityId && (
              <Box
                sx={{ position: 'fixed', top: '24px', left: '20px' }}
                onClick={onClose}
              >
                <BackDrawerButton direction="down" />
              </Box>
            )}

            <Box style={{ padding: '24px 20px 100px 20px', height: '150vh' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '20px',
                }}
              >
                <Box sx={{ alignSelf: 'flex-end', marginBottom: '40px' }}>
                  <Box component="img" src={LOGO_LIGHT} />
                </Box>
                <Typography
                  sx={{ fontSize: '24px', lineHeight: '29px' }}
                  variant="h4"
                >
                  {t('select_your_city')}
                </Typography>
              </Box>

              <SearchField handleChange={handleChangeValue} />
              {isCitiesLoading ? (
                <Box sx={{ padding: '50px', textAlign: 'center' }}>
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                <CitiesList cities={filteredCities} onClose={onClose} />
              )}
            </Box>
          </animated.div>
        </Box>
      )}
    </Portal>
  );
};

export default Drawer;
