import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectAccount = (state: RootState) => state.account;
/**
 * Returns account data
 */
export const selectAccountData = createSelector(
  [selectAccount],
  (account) => account.account.data
);
/**
 * Returns code data
 */
export const selectCodeData = createSelector(
  [selectAccount],
  (account) => account.code
);
/**
 * Returns verify data
 */
export const selectVerifyData = createSelector(
  [selectAccount],
  (account) => account.verify
);
/**
 * Returns account is loading
 */
export const selectAccountIsLoading = createSelector(
  [selectAccount],
  (account) => account.accountIsLoading
);
/**
 * Returns code data is loading
 */
export const selectCodeIsLoading = createSelector(
  [selectAccount],
  (account) => account.codeIsLoading
);
/**
 * Returns verify data is loading
 */
export const selectVerifyIsLoading = createSelector(
  [selectAccount],
  (account) => account.verifyIsLoading
);
/**
 * Returns the bonuses
 */
export const selectBonus = createSelector(
  [selectAccount],
  (account) => account.account?.data?.bonus_accounts[0]?.balance / 100 || 0
);
/**
 * Returns available bonuses
 */
export const selectAvailableBonus = createSelector(
  [selectAccount],
  (account) => account.availablePoints?.points / 100
);
/**
 * Returns feedback response
 */
export const selectFeedbackData = createSelector(
  [selectAccount],
  (account) => account.feedback
);
/**
 * Returns api_key
 */
export const selectApiKey = createSelector(
  [selectAccount],
  (account) => account.api_key
);
