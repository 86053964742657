import React, { FC, useEffect, useMemo } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectAccountData,
  selectAccountIsLoading,
} from 'store/selectors/accountSelectors';
import { TextField } from 'components/generic';
import { useTranslation } from 'react-i18next';

const ClientFrom: FC = () => {
  const { control, setValue, watch } = useFormContext();
  const phoneValue = watch('phone');
  const { first_name, phone } = useAppSelector(selectAccountData);
  const isLoading = useAppSelector(selectAccountIsLoading);
  const { t } = useTranslation();

  useEffect(() => {
    sessionStorage.setItem('phoneValue', phoneValue);
  }, [phoneValue]);

  useEffect(() => {
    if (first_name) {
      setValue('name', first_name);
    }
    if (phone) {
      setValue('phone', phone);
    }
  }, [first_name, phone]);

  const mask = useMemo(() => {
    return process.env.REACT_APP_DEFAULT_LANGUAGE === 'ru-RU'
      ? '+79999999999'
      : '';
  }, []);

  return (
    <>
      <Box sx={{ marginBottom: { xs: '13px', sm: '20px' } }}>
        {isLoading ? (
          <Skeleton variant="rectangular" height={45} />
        ) : (
          <TextField
            control={control}
            name="name"
            label={t('name')}
            size="small"
            required
          />
        )}
      </Box>
      <Box>
        {isLoading ? (
          <Skeleton variant="rectangular" height={45} />
        ) : (
          <TextField
            control={control}
            name="phone"
            label={t('phone_number')}
            size="small"
            required
            mask={mask}
          />
        )}
      </Box>
    </>
  );
};

export default ClientFrom;
