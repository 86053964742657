import React, { FC } from 'react';
import { Button, Typography, Box, Stack } from '@mui/material';
import { useTrimmed } from 'hooks';
import { useTranslation } from 'react-i18next';

interface FooterProps {
  price: string;
  description: string;
}

const Footer: FC<FooterProps> = ({ price, description }) => {
  const trimmedDescription = useTrimmed(description, 160);
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ paddingTop: { xs: '20px', md: '25px' } }}
    >
      <Typography
        sx={{
          flexGrow: 1,
          lineHeight: '1',
          overflow: 'hidden',
        }}
        variant="body1"
      >
        {trimmedDescription}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '15px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            lineHeight: '38px',
            whiteSpace: 'nowrap',
            fontWeight: '300',
            marginRight: '15px',
          }}
        >
          {price}
        </Typography>

        <Button
          sx={{
            width: '140px',
            height: '50px',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            fontWeight: '300',
            color: 'primary.contrastText',
            boxShadow: 'none',
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          {t('add_to_cart')}
        </Button>
      </Box>
    </Stack>
  );
};

export default Footer;
