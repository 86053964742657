import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Switch } from 'components/generic';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useFormContext } from 'react-hook-form';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { Delivery, Pickup } from 'components/basket/BasketContent/Receiving';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'hooks';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { selectIsDelivery } from 'store/selectors/orderSelectors';
import { addressSlice } from 'store/reducers/AddressSlice/AddressSlice';

interface ReceivingProps {
  handleAddressButton: () => void;
}

const Receiving: FC<ReceivingProps> = ({ handleAddressButton }) => {
  const dispatch = useAppDispatch();
  const { selectDeliveryType, addClientData, addComment } = orderSlice.actions;
  const { addDefaultAddressId } = addressSlice.actions;
  const { clearPickupTime } = orderSlice.actions;
  const { watch } = useFormContext();
  const selectedAddressId = watch('place');
  const nameValue = watch('name');
  const phoneValue = watch('phone');
  const commentValue = watch('comment');
  const name = useDebounce(nameValue, 500);
  const phone = useDebounce(phoneValue, 500);
  const comment = useDebounce(commentValue, 500);
  const isDelivery = useAppSelector<boolean>(selectIsDelivery);
  const { CARRY_OUT, DELIVERY } = useAppSelector<{
    CARRY_OUT: boolean;
    DELIVERY: boolean;
  }>(selectAppSettingsData);
  const { t } = useTranslation();
  const [delivery, setDelivery] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      addClientData({
        name,
        phone,
        app_version: '1.0',
      })
    );
  }, [phone, name]);

  useEffect(() => {
    dispatch(clearPickupTime());
  }, [isDelivery]);

  useEffect(() => {
    dispatch(addDefaultAddressId(Number(selectedAddressId)));
  }, [selectedAddressId]);

  useEffect(() => {
    dispatch(addComment(comment));
  }, [comment]);

  const handleChangeReceiving = (deliveryValue: boolean) => () => {
    setDelivery(deliveryValue);
  };

  useEffect(() => {
    dispatch(selectDeliveryType(Number(delivery)));
  }, [delivery]);

  return (
    <Box sx={{ padding: { xs: '0px', md: '0 25px' } }}>
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: '320px' },
          marginBottom: { xs: '14px', sm: '40px' },
        }}
      >
        {CARRY_OUT && DELIVERY && (
          <Switch
            firstValue={t('delivery')}
            secondValue={t('pickup')}
            value={delivery}
            handleChange={handleChangeReceiving}
          />
        )}
        {CARRY_OUT && !DELIVERY && (
          <Box
            sx={{
              fontSize: { xs: '16px', sm: '20px' },
              lineHeight: { xs: '20px', sm: '26px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: { xs: '40px', sm: '50px' },
              borderRadius: { xs: '20px', sm: '30px' },
              backgroundColor: 'primary.main',
            }}
          >
            {t('pick_up_an_order_from')}
          </Box>
        )}
        {!CARRY_OUT && DELIVERY && (
          <Box
            sx={{
              fontSize: { xs: '16px', sm: '20px' },
              lineHeight: { xs: '20px', sm: '26px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: { xs: '40px', sm: '50px' },
              borderRadius: { xs: '20px', sm: '30px' },
              backgroundColor: 'primary.main',
            }}
          >
            {t('deliver_to_the_address')}
          </Box>
        )}
      </Box>

      {CARRY_OUT && DELIVERY && (
        <>
          {isDelivery ? (
            <Delivery handleAddressButton={handleAddressButton} />
          ) : (
            <Pickup />
          )}
        </>
      )}
      {CARRY_OUT && !DELIVERY && <Pickup />}
      {!CARRY_OUT && DELIVERY && (
        <Delivery handleAddressButton={handleAddressButton} />
      )}
    </Box>
  );
};

export default Receiving;
