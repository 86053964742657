import React, { FC, useMemo, memo } from 'react';
import { AppBar, Toolbar, Box, Container } from '@mui/material';
import {
  Nav,
  Locality,
  Language,
  HeaderLogo,
  HeaderButtons,
} from 'components/generic/Header';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useMediaQuery } from 'react-responsive';
import { SelectCityModal } from 'components/modals';
import { City } from 'types/City';
import { selectCitiesData, selectCityId } from 'store/selectors/citySelectors';
import { useLanguages } from 'hooks';

const Header: FC = () => {
  const { LOGO_LIGHT, LOGO_DARK } = useAppSelector<{
    LOGO_LIGHT: string;
    LOGO_DARK: string;
  }>(selectAppSettingsData);
  const isMobile = useMediaQuery({ maxWidth: 900 });
  const cities = useAppSelector<City[]>(selectCitiesData);
  const languages = useLanguages();
  const cityId = useAppSelector(selectCityId);

  const isWelcomeMode = useMemo(() => {
    if (cityId || cities.length <= 1) {
      return false;
    }
    return true;
  }, [cityId, cities]);

  const logo = useMemo(() => {
    return process.env.REACT_APP_HEADER_LOGO === 'dark'
      ? LOGO_LIGHT
      : LOGO_DARK;
  }, [LOGO_LIGHT, LOGO_DARK]);

  return (
    <>
      <AppBar
        color="inherit"
        sx={{
          position: 'fixed',
          top: '0px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        {isMobile ? (
          <Box sx={{ height: '30px' }} />
        ) : (
          <>
            {cities.length < 2 && languages.length <= 1 ? (
              <Box sx={{ height: '30px' }} />
            ) : (
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    backgroundColor: 'primary.main',
                    zIndex: '-1',
                    opacity: '0.2',
                  }}
                />
                <Container>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '30px',
                    }}
                  >
                    {cities.length > 1 && <Locality />}
                    {languages.length > 1 && <Language />}
                  </Box>
                </Container>
              </Box>
            )}
          </>
        )}

        <Container>
          <Toolbar
            disableGutters
            sx={{
              justifyContent: 'space-between',
              position: 'relative',
              alignItems: 'center',
              minHeight: { xs: '70px', md: '80px' },
            }}
          >
            <HeaderLogo src={logo} />
            <Nav />
            <HeaderButtons />
          </Toolbar>
        </Container>
      </AppBar>
      <SelectCityModal isOpen={isWelcomeMode} onClose={() => null} />
    </>
  );
};

export default memo(Header);
