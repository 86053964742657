import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectBrandsData = (state: RootState) => state.brand;
/**
 * Returns brands list
 */
export const selectBrands = createSelector(
  [selectBrandsData],
  // (brandsReducer) => brandsReducer.brands
  (brandsData) => []
);
/**
 * Returns is brands
 */
export const selectIsBrands = createSelector(
  [selectBrands],
  (brands) => brands.length > 0
);
/**
 * Returns selected brand id
 */
export const selectBrandId = createSelector(
  [selectBrandsData],
  (brandsData) => brandsData.brand_id
);
