import React, { FC } from 'react';
import {
  StepOne,
  StepTwo,
  StepThree,
} from 'components/modals/AuthModal/Modal/AuthSteps';

interface AuthStepsProps {
  step: number;
  onClose: () => void;
  prevStep: () => void;
  nextStep: () => void;
}

const AuthSteps: FC<AuthStepsProps> = ({
  step,
  onClose,
  prevStep,
  nextStep,
}) => {
  switch (step) {
    case 2:
      return <StepTwo onClose={onClose} prevStep={prevStep} />;
    case 3:
      return <StepThree onClose={onClose} />;
    default:
      return <StepOne nextStep={nextStep} onClose={onClose} />;
  }
};

export default AuthSteps;
