import React, { FC } from 'react';
import { Dialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Content } from 'components/modals/BrandsModal/Modal';
import { Brand } from 'types/Brands';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  brands: Brand[];
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, brands }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          overflowY: 'inherit',
          maxWidth: '500px',
          padding: '15px',
        },
      }}
    >
      <IconButton
        sx={{ position: 'absolute', top: '-10px', right: '-65px' }}
        onClick={onClose}
        size="large"
        aria-label="close-btn"
      >
        <CloseIcon sx={{ color: 'common.white' }} fontSize="large" />
      </IconButton>
      <Content brands={brands} onClose={onClose} />
    </Dialog>
  );
};

export default Modal;
