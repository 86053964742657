import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from 'types/Account';
import {
  getAccount,
  updateAccountData,
  calculateAvailablePoint,
  postFeedback,
  getCode,
  verifyCode,
} from 'store/reducers/AccountSlice/ActionCreators';
import { ResponseAuthCode, ResponseVerifyCode } from 'types/Auth';
import { Cookies } from 'react-cookie';

//! chek what is it???
const cookies = new Cookies();

interface AccountState {
  account: Account;
  availablePoints: any;
  feedback: {
    reason: string;
    success: boolean;
  };
  api_key: string;
  code: ResponseAuthCode;
  verify: ResponseVerifyCode;
  accountIsLoading: boolean;
  availablePointsIsLoading: boolean;
  feedbackIsLoading: boolean;
  codeIsLoading: boolean;
  verifyIsLoading: boolean;
  error: string;
}

const initialState: AccountState = {
  account: {
    reason: null,
    success: false,
    data: {
      id: 0,
      is_superuser: false,
      phone: '',
      email: '',
      first_name: '',
      last_name: '',
      src: '',
      is_email_notification: false,
      is_sms_notification: false,
      is_push_notification: false,
      sex: 0,
      birthday: '',
      bonus_accounts: [],
      city_id: 0,
    },
  },
  availablePoints: null,
  feedback: {
    reason: '',
    success: false,
  },
  api_key: '',
  code: {
    reason: null,
    success: false,
    uid: '',
  },
  verify: {
    reason: null,
    success: false,
    api_key: '',
  },
  accountIsLoading: false,
  availablePointsIsLoading: false,
  feedbackIsLoading: false,
  codeIsLoading: false,
  verifyIsLoading: false,
  error: '',
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearFeedback: (state) => {
      state.feedback = {
        reason: '',
        success: false,
      };
    },
    removeAccountData: (state) => {
      state.account.data = {
        id: 0,
        is_superuser: false,
        phone: '',
        email: '',
        first_name: '',
        last_name: '',
        src: '',
        is_email_notification: false,
        is_sms_notification: false,
        is_push_notification: false,
        sex: 0,
        birthday: '',
        bonus_accounts: [],
        city_id: 0,
      };
    },
    addApiKey(state, action: PayloadAction<string>) {
      state.api_key = action.payload;
    },
    removeApiKey(state) {
      state.api_key = '';
    },
    clearAuthResponse(state) {
      state.code = {
        reason: null,
        success: false,
        uid: '',
      };
      state.error = '';
    },
    clearVerifyResponse(state) {
      state.verify = {
        reason: null,
        success: false,
        api_key: '',
      };
    },
  },
  extraReducers: {
    [getAccount.fulfilled.type]: (state, action: PayloadAction<Account>) => {
      state.accountIsLoading = false;
      state.error = '';
      state.account = action.payload;
    },
    [getAccount.pending.type]: (state) => {
      state.accountIsLoading = true;
    },
    [getAccount.rejected.type]: (state, action: PayloadAction<string>) => {
      state.accountIsLoading = false;
      state.error = action.payload;
    },
    [updateAccountData.fulfilled.type]: (
      state,
      action: PayloadAction<Account>
    ) => {
      state.accountIsLoading = false;
      state.error = '';
      if (!action.payload.success) {
        state.account.reason = action.payload.reason;
      }
      if (action.payload.success) {
        state.account = action.payload;
      }
    },
    [updateAccountData.pending.type]: (state) => {
      state.accountIsLoading = true;
    },
    [updateAccountData.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.accountIsLoading = false;
      state.error = action.payload;
    },
    [calculateAvailablePoint.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.availablePointsIsLoading = false;
      state.error = '';
      state.availablePoints = action.payload;
    },
    [calculateAvailablePoint.pending.type]: (state) => {
      state.availablePointsIsLoading = true;
    },
    [calculateAvailablePoint.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.availablePointsIsLoading = false;
      state.error = action.payload;
    },
    [postFeedback.fulfilled.type]: (
      state,
      action: PayloadAction<{ reason: string; success: boolean }>
    ) => {
      state.feedbackIsLoading = false;
      state.error = '';
      state.feedback = action.payload;
    },
    [postFeedback.pending.type]: (state) => {
      state.feedbackIsLoading = true;
    },
    [postFeedback.rejected.type]: (state, action: PayloadAction<string>) => {
      state.feedbackIsLoading = false;
      state.error = action.payload;
    },
    [getCode.fulfilled.type]: (
      state,
      action: PayloadAction<ResponseAuthCode>
    ) => {
      state.codeIsLoading = false;
      state.error = '';
      state.code = action.payload;
    },
    [getCode.pending.type]: (state) => {
      state.codeIsLoading = true;
    },
    [getCode.rejected.type]: (state, action: PayloadAction<string>) => {
      state.codeIsLoading = false;
      state.error = action.payload;
    },
    [verifyCode.fulfilled.type]: (
      state,
      action: PayloadAction<ResponseVerifyCode>
    ) => {
      state.verifyIsLoading = false;
      state.error = '';
      state.verify = action.payload;
      if (action.payload.success) {
        cookies.set('api_key', action.payload.api_key);
      }
    },
    [verifyCode.pending.type]: (state) => {
      state.verifyIsLoading = true;
    },
    [verifyCode.rejected.type]: (state, action: PayloadAction<string>) => {
      state.verifyIsLoading = false;
      state.error = action.payload;
    },
  },
});

export default accountSlice.reducer;
