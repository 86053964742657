import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MenuCategory } from 'types/Menu';

export const getMenu = createAsyncThunk(
  'menu/getMenu',
  async (params: { lang: string; city?: number; brand?: number }, thunkAPI) => {
    const { lang, city, brand } = params;
    try {
      const response = await axios.get<MenuCategory[]>(
        `${process.env.REACT_APP_V2}/menu/`,
        {
          headers: {
            'Accept-Language': lang,
          },
          params: {
            city,
            brand,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Can't get the menu list. Error: ${e}`);
    }
  }
);
