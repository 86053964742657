import React, { FC, useEffect, useState } from 'react';
import { Container, Typography, Box, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { MainTitle } from 'components/generic';
import {
  BackToCartButton,
  Bonuses,
  PaymentInfo,
  PaymentTypeSelect,
} from 'components/payment';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { createOrder } from 'store/reducers/OrderSlice/ActionCreators';
import {
  useCreateOrder,
  useOrderComplete,
  usePaymentButtonText,
} from 'hooks/order';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { useSelector } from 'react-redux';
import {
  selectDefaultPaymentType,
  selectOrderPaymentType,
  selectOrderTotalPrice,
  selectPayment,
  selectPaymentTypeOptions,
} from 'store/selectors/orderSelectors';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
/**
 * Page for collection payment information of a user
 */
const Payment: FC = () => {
  const { payment_type } = useSelector(selectPayment) || { payment_type: null };
  const defaultPaymentType = useSelector(selectDefaultPaymentType);
  const orderTotalPrice = useAppSelector(selectOrderTotalPrice);
  const paymentTypes = useSelector(selectPaymentTypeOptions);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { navigateOrderCreated, navigateOrderCreationFailed } =
    useOrderComplete();
  const dispatch = useAppDispatch();
  const { selectPaymentType } = orderSlice.actions;
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      payment_type: payment_type !== null ? payment_type : defaultPaymentType,
    },
  });
  const paymentTypeChange = watch('payment_type');
  const orderData = useCreateOrder();
  const { t } = useTranslation();
  const { BONUS_PROGRAM } = useAppSelector(selectAppSettingsData);
  const paymentType = useAppSelector(selectOrderPaymentType);
  const orderButtonText = usePaymentButtonText(paymentType);

  // Update payment type on state whenever it changes
  useEffect(() => {
    if (paymentTypeChange !== null && paymentTypeChange !== payment_type) {
      dispatch(selectPaymentType(Number(paymentTypeChange)));
    }
  }, [paymentTypeChange]);

  // Submit completed order
  const onSubmit = () => {
    setIsLoading(true);
    const orderPromise = dispatch(createOrder(orderData)).unwrap();

    orderPromise
      .then((createdOrderResponse) => {
        navigateOrderCreated(createdOrderResponse);
      })
      .catch((reason) => {
        navigateOrderCreationFailed(reason);
      });
  };

  return (
    <Box sx={{ padding: { xs: '30px 0', md: '40px 0 92px 0' } }}>
      <Container>
        <Box sx={{ marginBottom: { xs: '6px', sm: '50px' } }}>
          <BackToCartButton />
        </Box>
        <Box
          sx={{
            display: { xs: 'inline-flex', md: 'none' },
            marginBottom: { xs: '20px', sm: '64px' },
          }}
        >
          <MainTitle>{t('payment')}</MainTitle>
        </Box>
        <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: { xs: '100%', md: '50%' },
              marginBottom: { xs: '40px', md: '0px' },
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
          >
            <Box>
              <Box
                sx={{
                  display: { xs: 'none', md: 'inline-flex' },
                  marginBottom: { xs: '20px', sm: '64px' },
                }}
              >
                <MainTitle>{t('payment')}</MainTitle>
              </Box>

              <Box sx={{ marginBottom: { xs: '30px', sm: '40px' } }}>
                <PaymentTypeSelect
                  control={control}
                  paymentTypes={paymentTypes}
                  disabled={isLoading}
                />
              </Box>
            </Box>

            {BONUS_PROGRAM && (
              <Box sx={{ marginBottom: '30px' }}>
                <Bonuses />
              </Box>
            )}

            <Box>
              <Stack
                sx={{ marginBottom: { xs: '20px', md: '22px' } }}
                direction="row"
                spacing={1}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '30px' },
                    lineHeight: { xs: '35px' },
                    fontWeight: '400',
                  }}
                >
                  {t('total')}
                </Typography>
                <Stack direction="row" spacing={0.8}>
                  <Typography
                    sx={{
                      fontSize: { xs: '30px' },
                      lineHeight: { xs: '35px' },
                      fontWeight: '400',
                    }}
                  >
                    {orderTotalPrice}
                  </Typography>
                </Stack>
              </Stack>

              <LoadingButton
                sx={{
                  height: { xs: '43px', sm: '50px' },
                  fontSize: { xs: '16px', sm: '18px' },
                  lineHeight: { xs: '18px', sm: '21px' },
                  textTransform: 'inherit',
                  width: { xs: '100%', sm: '290px' },
                  boxShadow: 'none',
                  color: 'primary.contrastText',
                  fontWeight: '300',
                }}
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                {orderButtonText}
              </LoadingButton>
            </Box>
          </Box>
          <Box sx={{ width: { xs: '100%', md: '50%' } }}>
            <PaymentInfo />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Payment;
