import { createSelector } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { FormValues } from 'types/HookForms';
import { Ingredient, MenuCategory } from 'types/Menu';
import { RootState } from '../store';
import { selectPromotionsWithoutPromocode } from './promotionSelectors';

const selectMenu = (state: RootState) => state.menu;
/**
 * returns menu data
 */
export const selectMenuData = createSelector([selectMenu], (menu) => menu.menu);
/**
 * returns menu data is loading
 */
export const selectMenuIsLoading = createSelector(
  [selectMenu],
  (menuData) => menuData.isLoading
);
/**
 * returns selected category by ID
 */
export const selectCategory = (categoryId: number) =>
  createSelector(
    [selectMenuData],
    (menuData) =>
      menuData.filter((category: MenuCategory) => categoryId === category.id)[0]
  );
/**
 * returns items by category name
 */
export const selectItemsByCategoryName = (categoryId: number) =>
  createSelector([selectMenuData], (menuData) => {
    const category = menuData.find(
      (menuCategory: MenuCategory) => menuCategory.id === categoryId
    );

    return category?.items;
  });
/**
 * returns selected menu product
 */
export const selectMenuProduct = (itemId: number, categoryId: number) =>
  createSelector(
    [selectCategory(categoryId)],
    (menuCategory: MenuCategory) =>
      menuCategory?.items.filter((item) => item.id === itemId)[0]
  );
/**
 * returns price selected option
 */
export const selectOptionPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    const selectedOption = product?.options.find((option) => {
      if (selectedValues.option) {
        return option.id === Number(selectedValues.option);
      }
      return option.id;
    });
    if (selectedOption) {
      return selectedOption.price_kop;
    }
    return 0;
  });
/**
 * returns total price selected modifiers
 */
export const selectModifiersPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    if (product) {
      const selectedModifiers = product.group_modifiers.map((group) => {
        const selectedModifier = group.modifiers.find(
          (modifier) => modifier.id === Number(selectedValues[group.id])
        );
        const defaultSelectedModifiers = group.modifiers[0].price_kop;
        return selectedModifier
          ? selectedModifier.price_kop
          : defaultSelectedModifiers;
      });

      return selectedModifiers.reduce((sum, price) => sum + price, 0);
    }
    return 0;
  });
/**
 * returns total price selected ingredients
 */
export const selectIngredientsPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    const values = selectedValues.ingredients || [];
    const selectedIngredients = product?.ingredients.filter(
      (ingredient: Ingredient) => values.includes(ingredient.id.toString())
    );

    return (
      selectedIngredients?.reduce(
        (sum, ingredient: Ingredient) => sum + ingredient.price_kop,
        0
      ) || 0
    );
  });
/**
 * returns total price product
 */
export const selectCalculateProductPrice = (
  selectedValues: FormValues,
  itemId: number,
  categoryId: number
) =>
  createSelector(
    [
      selectOptionPrice(selectedValues, itemId, categoryId),
      selectModifiersPrice(selectedValues, itemId, categoryId),
      selectIngredientsPrice(selectedValues, itemId, categoryId),
    ],
    (optionPrice: number, modifiers: number, ingredientPrice: number) => {
      const totalPriceKop = optionPrice + modifiers + ingredientPrice;
      const totalPrice = Intl.NumberFormat(
        i18next.language || process.env.REACT_APP_DEFAULT_LANGUAGE,
        {
          style: 'currency',
          currency: process.env.REACT_APP_CURRENCY_CODE,
        }
      ).format(totalPriceKop / 100 || 0);

      return { totalPriceKop, totalPrice };
    }
  );
/**
 * returns card price
 */
export const selectMenuCardPrice = (itemId: number, categoryId: number) =>
  createSelector([selectMenuProduct(itemId, categoryId)], (product) => {
    const defaultPrice = product.options[0].price_kop;
    const defaultModifiersPrice = product.group_modifiers
      .map((group) => group.modifiers[0].price_kop)
      .reduce((prev, current) => prev + current, 0);
    return defaultPrice + defaultModifiersPrice;
  });
/**
 * returns converted data for display menu with promo
 */
export const selectMenuWithOffers = (promoCategoryName: string) =>
  createSelector(
    [selectMenuData, selectPromotionsWithoutPromocode],
    (menuData, promotions) => {
      const menuWithOffers = menuData
        .filter((category) => category.items.length > 0)
        .map((category) => {
          return {
            id: category.id,
            items: category.items.filter((item) => item.is_active),
            name: category.name,
            orderBy: category.order_by,
          };
        })
        .sort((prev, next) => prev.orderBy - next.orderBy);

      if (promotions.length > 0) {
        return [
          { id: 'promo', items: [], name: promoCategoryName, orderBy: 0 },
          ...menuWithOffers,
        ];
      }

      return menuWithOffers;
    }
  );
/**
 * returns converted data for display menu tabs with promo
 */
export const selectMenuTabs = (promoCategoryName: string) =>
  createSelector(
    [selectMenuData, selectPromotionsWithoutPromocode],
    (menuData, promotions) => {
      const convertedMenu = menuData
        .filter((category) => category.items.length > 0)
        .map((category) => {
          return {
            id: category.id,
            name: category.name,
            orderBy: category.order_by,
          };
        });

      if (promotions.length > 0) {
        return [
          { id: 'promo', name: promoCategoryName, orderBy: 0 },
          ...convertedMenu,
        ];
      }
      return convertedMenu;
    }
  );
/**
 * returns menu card product data for display
 */
export const selectCardProductData = (itemId: number, categoryId: number) =>
  createSelector(
    [selectMenuProduct(itemId, categoryId)],
    (menuItem) => menuItem
  );
