import { useEffect, useState } from 'react';
import axios from 'axios';
import useDebounce from 'hooks/useDebounce';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectApiKey } from 'store/selectors/accountSelectors';
import { KladrResult } from 'types/Kladr';
import { selectLocationCity } from 'store/selectors/citySelectors';

const useAutocomplete = (streetValue: string) => {
  const selectedLocationCity = useAppSelector(selectLocationCity);
  const [options, setOptions] = useState<KladrResult[]>([]);
  const apiKey = useAppSelector(selectApiKey);
  const debouncedValue = useDebounce<string>(streetValue, 200);

  useEffect(() => {
    let isMounted = true;
    const getKladrStreet = () => {
      try {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}kladr`, {
            headers: {
              Authorization: `api_key ${apiKey}`,
            },
            params: {
              cityId: selectedLocationCity?.okod,
              contentType: 'street',
              parentType: 'city',
              query: debouncedValue,
              limit: '3',
              parentId: selectedLocationCity?.okod,
            },
          })
          .then((res) => {
            const filteredFreeString = res.data.result.filter(
              (item: { id: string }) => item.id !== 'Free'
            );
            if (isMounted) {
              setOptions(filteredFreeString);
            }
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    getKladrStreet();
    return () => {
      isMounted = false;
    };
  }, [debouncedValue]);

  return options;
};

export default useAutocomplete;
