import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateOrderParams,
  OrderHistoryItem,
  ResponseCreateOrder,
} from 'types/Order';
import { CheckDelivery, CheckDeliveryResponse } from 'types/Delivery';
import { CalculateBasket, CalculatedBasket } from 'types/Basket';

export const createOrder = createAsyncThunk(
  'order/create_order',
  async (params: CreateOrderParams, thunkAPI) => {
    const { api_key, body } = params;
    try {
      const response = await axios.post<ResponseCreateOrder>(
        `${process.env.REACT_APP_V3}/order/`,
        body,
        {
          params: {
            api_key,
          },
        }
      );

      // Server side data validation won't reject request
      // rather set success to false.
      // if (!response.data.success) {
      //   return thunkAPI.rejectWithValue(
      //     `Can't place order. Reason: ${response.data.reason}`
      //   );
      // }

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Can't place order. Error: ${e}`);
    }
  }
);

export const getOrderHistory = createAsyncThunk(
  'order/order_history',
  async (api_key: string, thunkAPI) => {
    try {
      const response = await axios.get<OrderHistoryItem[]>(
        `${process.env.REACT_APP_V1}/account/order_history/`,
        {
          params: {
            api_key,
          },
        }
      );

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Can't get the order history. Error: ${e}`
      );
    }
  }
);

export const checkDelivery = createAsyncThunk(
  'order/check_delivery',
  async (params: { api_key: string; body: CheckDelivery }, thunkAPI) => {
    const { api_key, body } = params;
    try {
      const response = await axios.post<CheckDeliveryResponse>(
        `${process.env.REACT_APP_V3}/order/check_delivery/`,
        body,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Не удалось проверить возможность доставки, ${e}`
      );
    }
  }
);

export const calculateBasket = createAsyncThunk(
  'basket/calculateBasket',
  async (
    params: {
      api_key: string;
      body: CalculateBasket;
      platform?: number;
      brand_id?: number;
    },
    thunkAPI
  ) => {
    const { api_key, platform, brand_id, body } = params;
    try {
      const response = await axios.post<CalculatedBasket>(
        `${process.env.REACT_APP_V3}/order/calculate_basket/`,
        body,
        {
          params: {
            api_key,
            platform,
            brand_id,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось сформировать корзину');
    }
  }
);
