import React, { FC } from 'react';
import {
  Account,
  Cart,
  About,
  Menu,
  PageNotFoundView,
  Promotions,
  Payment,
  // Reserve,
  Blog,
  BlogPost,
  PaymentSuccess,
  ErrorPage,
  OrderSuccess,
  Delivery,
  PrivacyPolicy,
  TermsOfUse,
  Home,
  QrPage,
  Gallery,
  Reserve,
  Vacancies,
  Reviews,
} from 'pages';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AppLayout, ReviewsLayout } from 'layouts';
import { PrivateRoute, Rtl, ScrollToTop } from 'components/generic';
import { useInitApp } from 'hooks';
import { ThemeProvider } from '@mui/material';
import { useStyles } from 'hooks/styles';
import { Helmet } from 'react-helmet';
import { useAuth } from 'hooks/auth';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectBlogPosts } from 'store/selectors/blogSelectors';
import { HighRating, LowRating } from 'components/reviews';

const App: FC = () => {
  useInitApp();
  const theme = useStyles();
  const location = useLocation();
  const { isAuth } = useAuth();
  const blogPosts = useAppSelector(selectBlogPosts);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={process.env.REACT_APP_NAME} />
        <title>{process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Rtl>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <Routes location={location}>
            {process.env.REACT_APP_IS_QR_PAGE === 'true' && (
              <>
                <Route path="/qr" element={<QrPage />} />
                <Route path="/qrpage" element={<QrPage />} />
              </>
            )}
            <Route path="/" element={<AppLayout />}>
              {process.env.REACT_APP_IS_HOME_PAGE === 'true' && (
                <Route index element={<Home />} />
              )}
              <Route
                index={process.env.REACT_APP_IS_HOME_PAGE === 'false'}
                path={
                  process.env.REACT_APP_IS_HOME_PAGE === 'false' ? '/' : '/menu'
                }
                element={<Menu />}
              />
              <Route path="promotions" element={<Promotions />} />
              {process.env.REACT_APP_IS_ABOUT_PAGE === 'true' && (
                <Route path="about" element={<About />} />
              )}
              <Route path="cart" element={<Cart />} />

              {process.env.REACT_APP_IS_BOOKING_PAGE === 'true' && (
                <Route path="reserve" element={<Reserve />} />
              )}
              {blogPosts.length > 0 && (
                <>
                  <Route path="blog" element={<Blog />} />
                  <Route path="blog/:postId" element={<BlogPost />} />
                </>
              )}
              {process.env.REACT_APP_IS_REVIEWS_PAGE === 'true' && (
                <Route path="reviews" element={<ReviewsLayout />}>
                  <Route index element={<Reviews />} />
                  <Route path="high" element={<HighRating />} />
                  <Route path="low" element={<LowRating />} />
                </Route>
              )}
              <Route path="payment-success" element={<PaymentSuccess />} />
              <Route path="order-success" element={<OrderSuccess />} />
              <Route path="delivery" element={<Delivery />} />
              <Route path="privacy_policy" element={<PrivacyPolicy />} />
              <Route path="terms_of_use" element={<TermsOfUse />} />
              <Route path="vacancies" element={<Vacancies />} />
              <Route path="error" element={<ErrorPage />} />
              <Route path="*" element={<PageNotFoundView />} />
              {process.env.REACT_APP_IS_GALLERY_PAGE === 'true' && (
                <Route path="gallery" element={<Gallery />} />
              )}
              {isAuth && (
                <>
                  <Route
                    path="account"
                    element={
                      <PrivateRoute>
                        <Account />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="payment"
                    element={
                      <PrivateRoute>
                        <Payment />
                      </PrivateRoute>
                    }
                  />
                </>
              )}
            </Route>
          </Routes>
        </ThemeProvider>
      </Rtl>
    </>
  );
};

export default App;
