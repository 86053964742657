import React, { FC, useCallback } from 'react';
import { Box, Container, Stack } from '@mui/material';
import { DeliveryItem } from 'components/delivery';
import { SubTitle, BackButton } from 'components/generic';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectDeliveryPageData } from 'store/selectors/pageSelectors';
import { useTranslation } from 'react-i18next';
import Iframe from 'react-iframe';
import { Helmet } from 'react-helmet';
import Parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';

const Delivery: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { delivery_zones, delivery_list, delivery_text } = useAppSelector(
    selectDeliveryPageData
  );

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {t('delivery')}
        </title>
      </Helmet>

      <Box sx={{ padding: { xs: '30px 0', md: '40px 0' } }}>
        <Container>
          <Box sx={{ marginBottom: '20px' }}>
            <BackButton handleBack={handleBack} />
          </Box>
          <Box sx={{ marginBottom: '30px' }}>
            <SubTitle>{t('delivery')}</SubTitle>
          </Box>

          <Stack
            direction="column"
            spacing={{ xs: 2, sm: 4 }}
            sx={{ padding: { xs: '0px', md: '0px 25px' } }}
          >
            {delivery_list.map((item) => (
              <DeliveryItem key={item.id} title={item.title} text={item.text} />
            ))}

            <Iframe
              url={delivery_zones}
              width="100%"
              height="400px"
              position="relative"
              frameBorder={0}
            />
          </Stack>
          <Box
            sx={{
              fontSize: { xs: '16px', sm: '18px' },
              lineHeight: { xs: '18px', sm: '22px' },
              fontWeight: '300',
              padding: { xs: '0px', md: '20px 25px' },
            }}
          >
            {Parse(delivery_text)}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Delivery;
