import React, { FC, useEffect, memo } from 'react';
import { Box } from '@mui/material';
import { Card, CategoryTitle, PromotionSlider } from 'components/menu';
import { MenuProduct } from 'types/Menu';
import { useActiveMenuObserver, useCategoryObserver } from 'hooks/menu';

interface CategoryProps {
  activeCategory: number | string;
  categoryRefs: any;
  items: MenuProduct[];
  categoryId: number | string;
  categoryName: string;
  rootMargin: string;
  handleSetActiveCategory: (arg: number | string) => void;
}

const Category: FC<CategoryProps> = ({
  activeCategory,
  categoryRefs,
  items,
  categoryId,
  categoryName,
  rootMargin,
  handleSetActiveCategory,
}) => {
  const isIntersecting = useCategoryObserver(categoryRefs[categoryName]);
  const activeCat = useActiveMenuObserver(
    categoryRefs[categoryName],
    activeCategory,
    handleSetActiveCategory,
    {
      rootMargin,
    }
  );

  useEffect(() => {
    handleSetActiveCategory(activeCat);
  }, [activeCategory]);

  return (
    <Box
      sx={{
        marginBottom: { xs: '32px', sm: '70px' },
      }}
      ref={categoryRefs[categoryName]}
      id={categoryId.toString()}
    >
      <Box sx={{ marginBottom: { xs: '9px', sm: '35px' } }}>
        <CategoryTitle>{categoryName}</CategoryTitle>
      </Box>

      {categoryId === 'promo' && <PromotionSlider />}

      <Box
        sx={{
          display: 'grid',
          columnGap: { xs: 1, sm: 2, md: 4, lg: 5, xl: 5 },
          rowGap: { xs: 2, sm: 3, md: 4, lg: 5, xl: 5 },
          gridTemplateColumns: {
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(3, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
            xl: 'repeat(4, 1fr)',
          },
        }}
      >
        {items.map((item: MenuProduct) => (
          <Card
            productId={item.id}
            categoryId={item.parent_menu}
            key={item.id}
            isIntersecting={isIntersecting}
          />
        ))}
      </Box>
    </Box>
  );
};

export default memo(Category);
