import React, { FC } from 'react';
import { Box, RadioGroup, Typography } from '@mui/material';
import { GroupModifier } from 'types/Menu';
import { OptionCheckbox, OptionRadioButton } from 'components/generic';

interface GroupModifiersProps {
  groupModifiers: GroupModifier[];
}

const GroupModifiers: FC<GroupModifiersProps> = ({ groupModifiers }) => {
  return (
    <Box>
      {groupModifiers.map((group) => (
        <Box sx={{ marginBottom: '14px' }} key={group.id}>
          <Typography
            sx={{ fontWeight: '400', marginBottom: '10px' }}
            variant="h6"
          >
            {group.name}
          </Typography>
          {group.max_count <= 1 ? (
            <RadioGroup
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              aria-label={group.name}
              defaultValue={group.modifiers[0].id}
              name="radio-buttons-group"
            >
              {group.modifiers.map((modifier) => (
                <OptionRadioButton
                  key={modifier.id}
                  label={modifier.name}
                  group={group.id}
                  value={modifier.id}
                  price={modifier.price_kop}
                  defaultValue={group.modifiers[0].id}
                />
              ))}
            </RadioGroup>
          ) : (
            <Box>
              {group.modifiers.map((modifier) => (
                <OptionCheckbox
                  key={modifier.id}
                  label={modifier.name}
                  group={group.id}
                  value={modifier.id}
                  price={modifier.price_kop}
                />
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default GroupModifiers;
