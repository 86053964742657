import React, { FC, useRef, memo } from 'react';
import { Box, IconButton } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import SwiperCore, { Autoplay, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectPromotionsWithoutPromocode } from 'store/selectors/promotionSelectors';
import { Card } from 'components/menu/PromotionSlider';
import { useTranslation } from 'react-i18next';

const PromotionSlider: FC = () => {
  const promotions = useAppSelector(selectPromotionsWithoutPromocode);
  SwiperCore.use([Autoplay, Mousewheel, Navigation]);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const { i18n } = useTranslation();
  const isLtr = i18n.dir() === 'ltr';

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton
        sx={{
          display: { xs: 'none', md: 'inline-flex' },
          marginRight: '10px',
          position: 'absolute',
          left: '-50px',
          top: '0',
          bottom: '0',
          height: '40px',
          margin: 'auto',
          '&.swiper-button-disabled': {
            opacity: 0,
            cursor: 'inherit',
          },
        }}
        size="medium"
        ref={navigationPrevRef}
      >
        {isLtr ? (
          <ArrowBackIosNew fontSize="medium" color="primary" />
        ) : (
          <ArrowForwardIos fontSize="medium" color="primary" />
        )}
      </IconButton>

      <Swiper
        spaceBetween={25}
        freeMode
        centeredSlidesBounds
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        breakpoints={{
          '300': {
            slidesPerView: 1.4,
            spaceBetween: 18,
          },
          '480': {
            slidesPerView: 1.9,
            spaceBetween: 18,
          },
          '567': {
            slidesPerView: 1.9,
            spaceBetween: 18,
          },
          '767': {
            slidesPerView: 2.3,
            spaceBetween: 15,
          },
          '991': {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          '1250': {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          '1400': {
            slidesPerView: 2.7,
            spaceBetween: 20,
          },
        }}
      >
        {promotions.map((promotion) => (
          <SwiperSlide key={promotion.id}>
            <Card
              image={promotion.thumbnail_url}
              desktopImage={promotion.pictures[0].src}
              title={promotion.name}
              description={promotion.description}
              date={promotion.date}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <IconButton
        sx={{
          display: { xs: 'none', md: 'inline-flex' },
          marginLeft: '10px',
          position: 'absolute',
          right: '-50px',
          top: '0',
          bottom: '0',
          height: '40px',
          margin: 'auto',
          '&.swiper-button-disabled': {
            opacity: 0,
            cursor: 'inherit',
          },
        }}
        size="medium"
        ref={navigationNextRef}
      >
        {isLtr ? (
          <ArrowForwardIos fontSize="medium" color="primary" />
        ) : (
          <ArrowBackIosNew fontSize="medium" color="primary" />
        )}
      </IconButton>
    </Box>
  );
};

export default memo(PromotionSlider);
