import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectPagesData = (state: RootState) => state.pages;
/**
 * Returns page data
 */
export const selectPage = (slug: string) =>
  createSelector([selectPagesData], (pagesData) =>
    pagesData.pages.find((page) => page.slug === slug)
  );
/**
 * Returns data for reserve page
 */
export const selectReservePageData = createSelector(
  [selectPage('reserve')],
  (page) => {
    return {
      page_title: page?.fields?.page_title || '',
      info_title: page?.fields?.info_title || '',
      info_text: page?.fields?.info_text || '',
      info_image: page?.fields?.info_image || '',
      banner_image: page?.fields?.banner_image || '',
      form_image: page?.fields?.form_image || '',
    };
  }
);
/**
 * Returns data for about us page
 */
export const selectAboutUsPageData = createSelector(
  [selectPage('about')],
  (page) => {
    return {
      page_title: page?.fields?.page_title || '',
      info_title_1: page?.fields?.info_title_1 || '',
      info_title_2: page?.fields?.info_title_2 || '',
      info_text_1: page?.fields?.info_text_1 || '',
      info_text_2: page?.fields?.info_text_2 || '',
      info_image: page?.fields?.info_image || '',
      banner_image: page?.fields?.banner_image || '',
      advantages: page?.fields?.advantages || [],
      is_contacts: page?.fields?.is_contacts || false,
    };
  }
);
/**
 * Returns data for home page
 */
export const selectHomePageData = createSelector(
  [selectPage('home')],
  (page) => {
    return {
      main_banner_title: page?.fields?.main_banner_title || '',
      banner_title_1: page?.fields?.banner_title_1 || '',
      banner_title_2: page?.fields?.banner_title_2 || '',
      main_banner_text: page?.fields?.main_banner_text || '',
      banner_text_1: page?.fields?.banner_text_1 || '',
      banner_text_2: page?.fields?.banner_text_2 || '',
      main_banner_image: page?.fields?.main_banner_image || '',
      banner_image_1: page?.fields?.banner_image_1 || '',
      banner_image_2: page?.fields?.banner_image_2 || '',
      home_title_1: page?.fields?.home_title_1 || '',
      home_title_2: page?.fields?.home_title_2 || '',
      home_text_1: page?.fields?.home_text_1 || '',
      home_text_2: page?.fields?.home_text_2 || '',
      offer_image_1: page?.fields?.offer_image_1 || '',
      offer_image_2: page?.fields?.offer_image_2 || '',
      offer_image_3: page?.fields?.offer_image_3 || '',
      offer_mobile_image_1: page?.fields?.offer_mobile_image_1 || '',
      offer_mobile_image_2: page?.fields?.offer_mobile_image_2 || '',
      offer_mobile_image_3: page?.fields?.offer_mobile_image_3 || '',
      offer_mobile_bg_1: page?.fields?.offer_mobile_bg_1 || '',
      offer_mobile_bg_2: page?.fields?.offer_mobile_bg_2 || '',
      offer_mobile_bg_3: page?.fields?.offer_mobile_bg_3 || '',
      banner_mobile_image_2: page?.fields?.banner_mobile_image_2 || '',
      burger: page?.fields?.burger || '',
      main_banner_logo: page?.fields?.main_banner_logo || '',
      opportunities: page?.fields?.opportunities || [],
      reviews: page?.fields?.reviews || [],
    };
  }
);
/**
 * Returns data for delivery page
 */
export const selectDeliveryPageData = createSelector(
  [selectPage('delivery')],
  (page) => {
    return {
      delivery_zones: page?.fields?.delivery_zones || '',
      delivery_list: page?.fields?.delivery_list || [],
      delivery_text: page?.fields?.text || '',
    };
  }
);
/**
 * Returns data for delivery page
 */
export const selectPrivacyPolicyPageData = createSelector(
  [selectPage('privacy_policy')],
  (page) => {
    return {
      text: page?.fields?.text || '',
    };
  }
);
/**
 * Returns data for delivery page
 */
export const selectTermsOfUsePageData = createSelector(
  [selectPage('terms_of_use')],
  (page) => {
    return {
      text: page?.fields?.text || '',
    };
  }
);
/**
 * Returns data for delivery page
 */
export const selectImagesData = createSelector(
  [selectPage('images')],
  (page) => {
    return {
      image404: page?.fields?.page_404 || '',
      footer_mobile_bg: page?.fields?.footer_mobile_bg || '',
      footer_desktop_bg: page?.fields?.footer_desktop_bg || '',
      dpl_logo: page?.fields?.dpl_logo || '',
      qr_code: page?.fields?.qr_code || '',
      qr_slider: page?.fields?.qr_slider || [],
      reviews_bg: page?.fields?.reviews_bg || '',
    };
  }
);
/**
 * Returns data for vacancies page
 */
export const selectVacanciesPageData = createSelector(
  [selectPage('vacancies')],
  (page) => {
    return {
      text: page?.fields?.text || '',
    };
  }
);
