import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { BackButton } from 'components/generic';
import { selectImagesData } from 'store/selectors/pageSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

const ReviewsLayout: FC = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const { reviews_bg } = useAppSelector(selectImagesData);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        padding: { xs: '30px 0', md: '40px 0' },
        minHeight: '80vh',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          bottom: '0px',
          width: '100%',
          height: '100%',
          zIndex: '-1',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            minHeight: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          src={reviews_bg}
          component="img"
        />
      </Box>
      <Container>
        <FormProvider {...methods}>
          <Box sx={{ marginBottom: '20px' }}>
            <BackButton handleBack={handleBack} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
            component="form"
          >
            <Outlet />
          </Box>
        </FormProvider>
      </Container>
    </Box>
  );
};

export default ReviewsLayout;
