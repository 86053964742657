import React, { FC } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { Cookie as CookieIcon } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

const CookiesBanner: FC = () => {
  const [cookie, setCookie] = useCookies(['cookie_consent']);
  const { t } = useTranslation();

  const handleCookiesConsent = () => {
    setCookie('cookie_consent', true, { maxAge: 2592000 });
  };

  return (
    <>
      {!cookie.cookie_consent ? (
        <Box
          sx={{
            position: 'fixed',
            bottom: '0',
            left: '0',
            width: '100vw',
            zIndex: '1500',
            backgroundColor: 'secondary.main',
          }}
        >
          <Container>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                padding: '30px 0',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                  flexDirection: { xs: 'column', md: 'row' },
                }}
              >
                <CookieIcon color="primary" fontSize="large" />
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: 'white',
                    fontWeight: '300',
                    textAlign: { xs: 'center', md: 'left' },
                  }}
                >
                  {t('cookies_banner.description')}
                </Typography>
              </Box>

              <Button
                sx={{
                  minWidth: '210px',
                  height: '40px',
                  fontSize: '16px',
                  lineHeight: '18px',
                  color: 'primary.contrastText',
                  flexGrow: '1',
                  boxShadow: 'none',
                }}
                variant="contained"
                onClick={handleCookiesConsent}
              >
                {t('cookies_banner.button')}
              </Button>
            </Box>
          </Container>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default CookiesBanner;
