import React, { FC } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { PaymentTypeOption } from 'types/Order';

interface PaymentTypeSelectProps {
  control: Control<{ payment_type: number | null }>;
  paymentTypes: PaymentTypeOption[];
  disabled?: boolean;
}

/**
 * A control for payment type selecting.
 * Note: use react-hook-form control object to get a selected option.
 */
const PaymentTypeSelect: FC<PaymentTypeSelectProps> = ({
  control,
  paymentTypes,
  disabled = false,
}) => {
  return (
    <>
      <Controller
        name="payment_type"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            aria-label="Payment type"
            name="payment_type"
            value={value}
            onChange={onChange}
          >
            {paymentTypes.map((method) => (
              <FormControlLabel
                key={method.value}
                value={method.value}
                control={
                  <Radio
                    sx={{
                      color: 'text.primary',
                    }}
                  />
                }
                label={method.label}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
        )}
      />
    </>
  );
};

export default PaymentTypeSelect;
