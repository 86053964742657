import { createSelector } from '@reduxjs/toolkit';
import { Address } from 'types/Address';
import { RootState } from '../store';

const selectAddresses = (state: RootState) => state.addresses;
/**
 * Returns addresses
 */
export const selectAddressesData = createSelector(
  [selectAddresses],
  (addresses) => addresses.addresses
);
/**
 * Returns addresses is loading
 */
export const selectAddressIsLoading = createSelector(
  [selectAddresses],
  (addresses) => addresses.isLoading
);
/**
 * Returns address by ID
 */
export const selectAddressById = (id?: number) =>
  createSelector([selectAddressesData], (addressesData) =>
    addressesData.find((address: Address) => address.id === id)
  );
/**
 * Returns default address ID
 */
export const selectDefaultAddressId = createSelector(
  [selectAddresses],
  (addresses) => addresses.defaultAddressId
);
/**
 * Returns converted address data to display in the address list
 */
export const selectConvertedAddress = createSelector(
  [selectAddressesData],
  (addressesData) => {
    const addressList = addressesData.map((address: Address) => {
      const cityName = address.city_name ? `${address.city_name}, ` : '';
      const street = address.street ? `${address.street}, ` : '';
      const area = address.area ? `${address.area}, ` : '';
      const house = address.house ? `${address.house}, ` : '';
      const apartment = address.apartment ? `${address.apartment}, ` : '';
      const entrance = address.entrance ? `${address.entrance}, ` : '';
      const floor = address.floor ? `${address.floor}, ` : '';
      const corpus = address.corpus ? `${address.corpus}, ` : '';

      const finalItem =
        cityName +
        street +
        area +
        house +
        apartment +
        entrance +
        floor +
        corpus;

      return {
        id: address.id,
        name: address.name ? `${address.name}: ` : '',
        item: `${finalItem.slice(0, -2)}.`,
      };
    });

    return addressList;
  }
);
/**
 * Returns data for the select field on the basket page
 */
export const selectAddressesList = createSelector(
  [selectAddressesData, selectConvertedAddress],
  (addressesData, convertedAddresses) =>
    addressesData.map((address) => {
      const addressConverted =
        convertedAddresses.find((item) => item.id === address.id)?.item || '';

      return {
        label: address.name ? address.name : addressConverted,
        value: address.id,
      };
    })
);
/**
 * Returns address data for basket calculation
 */
export const selectAddressForBasket = createSelector(
  [selectAddresses, selectDefaultAddressId],
  (addresses, defaultAddressId) => {
    const selectedAddress = addresses.addresses.find(
      (address: Address) => address.id === Number(defaultAddressId)
    );
    if (selectedAddress) {
      return {
        city_id: selectedAddress.city,
        street: selectedAddress.street,
        house: selectedAddress.house,
        building: selectedAddress.building,
        corpus: selectedAddress.corpus,
        apartment: selectedAddress.apartment,
        entrance: selectedAddress.entrance,
        floor: selectedAddress.floor,
        area: selectedAddress.area,
        latitude: selectedAddress.latitude,
        longitude: selectedAddress.longitude,
      };
    }
    return null;
  }
);
