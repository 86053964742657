import React, { FC } from 'react';
import { Stack, Box } from '@mui/material';
import { Brand } from 'types/Brands';
import { Card } from 'components/modals/BrandsModal/Modal/Content';

interface ContentProps {
  brands: Brand[];
  onClose: () => void;
}

const Content: FC<ContentProps> = ({ brands, onClose }) => {
  return (
    <Box
      sx={{
        maxHeight: '700px',
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: 7,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'grey.400',
          borderRadius: '20px',
        },
      }}
    >
      <Stack direction="column" spacing={0.5}>
        {brands.map((brand) => (
          <Card
            key={brand.id}
            id={brand.id}
            name={brand.name}
            description={brand.short_description}
            logo={brand.logo}
            image={brand.image}
            onClose={onClose}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default Content;
