import React, { FC, useMemo } from 'react';
import { Stack, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Links: FC = () => {
  const { t } = useTranslation();

  const isDelivery = useMemo(() => {
    if (process.env.REACT_APP_FOOTER_IS_DELIVERY_LINK === 'true') {
      return true;
    }
    return false;
  }, []);

  const isPrivacyPolicy = useMemo(() => {
    if (process.env.REACT_APP_FOOTER_IS_PRIVACY_POLICY_LINK === 'true') {
      return true;
    }
    return false;
  }, []);

  const isTermsOfUse = useMemo(() => {
    if (process.env.REACT_APP_FOOTER_IS_TERMS_OF_USE_LINK === 'true') {
      return true;
    }
    return false;
  }, []);

  const isVacancies = useMemo(() => {
    if (process.env.REACT_APP_FOOTER_IS_VACANCIES_LINK === 'true') {
      return true;
    }
    return false;
  }, []);

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 3 }}>
      {isDelivery && (
        <Link
          sx={{
            textDecoration: 'none',
            color: process.env.REACT_APP_FOOTER_TEXT_COLOR,
            lineHeight: '1',
          }}
          to="/delivery"
          component={NavLink}
        >
          {t('delivery')}
        </Link>
      )}
      {isPrivacyPolicy && (
        <Link
          sx={{
            textDecoration: 'none',
            color: process.env.REACT_APP_FOOTER_TEXT_COLOR,
            lineHeight: '1',
          }}
          to="/privacy_policy"
          component={NavLink}
        >
          {t('privacy_policy')}
        </Link>
      )}
      {isTermsOfUse && (
        <Link
          sx={{
            textDecoration: 'none',
            color: process.env.REACT_APP_FOOTER_TEXT_COLOR,
            lineHeight: '1',
          }}
          to="/terms_of_use"
          component={NavLink}
        >
          {t('terms_of_use')}
        </Link>
      )}
      {isVacancies && (
        <Link
          sx={{
            textDecoration: 'none',
            color: process.env.REACT_APP_FOOTER_TEXT_COLOR,
            lineHeight: '1',
          }}
          to="/vacancies"
          component={NavLink}
        >
          {t('vacancies')}
        </Link>
      )}
    </Stack>
  );
};

export default Links;
