import { useMemo } from 'react';
import { CookingOption, GroupModifier, Ingredient, Option } from 'types/Menu';

const useCalculateProduct = (
  values: { [x: string]: any },
  totalPriceKop: number,
  randomId: number,
  src: string,
  name: string,
  group_modifiers: GroupModifier[],
  options: Option[],
  ingredients: Ingredient[],
  description: string,
  is_only_pickup: boolean,
  cooking_options: CookingOption[]
) => {
  // Returns the converted selected modifiers
  const convertedSelectedModifiers = useMemo(() => {
    if (group_modifiers) {
      return group_modifiers.map((group) => {
        return {
          id: group.id,
          modifiers: [Number(values[group.id])],
        };
      });
    }
    return [];
  }, [group_modifiers, values]);

  // Returns the selected modifiers
  const selectedModifiers = useMemo(() => {
    return group_modifiers.map((group) => {
      return group.modifiers.filter((modifier) => {
        if (Array.isArray(values[group.id])) {
          return values[group.id]
            .map((item: string) => Number(item))
            .includes(modifier.id);
        }
        return modifier.id === Number(values[group.id]);
      });
    });
  }, [group_modifiers, values]);

  // Returns the selected options
  const selectedOption = useMemo(() => {
    return options.filter(
      (option: Option) => option.id === Number(values.option)
    )[0];
  }, [values, options]);

  // Returns the selected ingredients
  const selectedIngredients = useMemo(() => {
    if (values.ingredients) {
      return values.ingredients.map((ingredientId: string) =>
        Number(ingredientId)
      );
    }
    return [];
  }, [values.ingredients]);

  // Returns the selected cooking option
  const selectedCookingOption = useMemo(() => {
    if (values.cooking_options) {
      return Number(values.cooking_options);
    }
    return null;
  }, [values.cooking_options, cooking_options]);

  // Returns the selected ingredients for basket
  const ingredientsList = useMemo(() => {
    return ingredients
      .filter((ingredient: Ingredient) =>
        selectedIngredients.includes(ingredient.id)
      )
      .map((item) => {
        return {
          id: item.id,
          count: 1,
        };
      });
  }, [selectedIngredients, ingredients]);

  // Description of the selected product
  const calculatedDescription = useMemo(() => {
    const selectedModefiersDesc = selectedModifiers
      .map((modifier) => modifier.map((item) => item.name))
      .join(', ');

    const selectedIngredientsDesc = ingredients
      .filter((ingredient: Ingredient) =>
        selectedIngredients.includes(ingredient.id)
      )
      .map((item) => item.name)
      .join(', ');

    const selectedCookingOptionDesc = cooking_options.filter(
      (option) => option.id === selectedCookingOption
    )[0]?.name;

    const modefiersDesc = selectedModefiersDesc || '';
    const ingredientsDesc = selectedIngredientsDesc || '';
    const cookingOptionDesc = selectedCookingOptionDesc || '';
    const optionDesc = selectedOption?.description || '';

    if (optionDesc || modefiersDesc || ingredientsDesc || cookingOptionDesc) {
      return `${optionDesc} ${modefiersDesc} ${ingredientsDesc} ${cookingOptionDesc}`;
    }
    return description;
  }, [selectedOption, selectedModifiers, description]);

  return {
    id: selectedOption ? selectedOption.id : options[0].id,
    cooking_option_id: selectedCookingOption,
    title: name,
    image: src,
    total_price: totalPriceKop,
    count: 1,
    group_modifiers: convertedSelectedModifiers,
    ingredients: ingredientsList,
    description: calculatedDescription,
    isCount: false,
    is_only_pickup,
    deleteId: randomId,
  };
};

export default useCalculateProduct;
