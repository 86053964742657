import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectSocialMediaData = (state: RootState) => state.socialMedia;
/**
 * Returns social media
 */
export const selectSocialMedia = createSelector(
  [selectSocialMediaData],
  (socialMediaData) => socialMediaData.socialMedia
);
