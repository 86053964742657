import React, { FC } from 'react';
import {
  Banner,
  Map,
  Welcome,
  Info,
  Advantages,
  Contacts,
} from 'components/about';
import { selectAboutUsPageData } from 'store/selectors/pageSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectAvailableRestaurants,
  selectFirstAvailableRestaurant,
} from 'store/selectors/restaurantSelectors';
import { useScrollToBlock } from 'hooks';
import { Restaurant } from 'types/Restaurant';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const About: FC = () => {
  useScrollToBlock();
  const {
    page_title,
    info_title_1,
    info_title_2,
    info_text_1,
    info_text_2,
    info_image,
    banner_image,
    advantages,
    is_contacts,
  } = useAppSelector(selectAboutUsPageData);
  const restaurants = useAppSelector<Restaurant[]>(selectAvailableRestaurants);
  const { longitude, latitude } = useAppSelector<{
    longitude: number;
    latitude: number;
  }>(selectFirstAvailableRestaurant);
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {t('about_us')}
        </title>
      </Helmet>
      <Banner title={page_title} src={banner_image} />
      <Welcome title={info_title_1} text={info_text_1} />
      <Info title={info_title_2} text={info_text_2} src={info_image} />
      {advantages.length > 0 && <Advantages advantages={advantages} />}
      {is_contacts && <Contacts />}
      <Map
        zoom={11}
        longitude={longitude}
        latitude={latitude}
        restaurants={restaurants}
      />
    </>
  );
};

export default About;
