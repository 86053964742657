import { useMemo } from 'react';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectBlogPosts } from 'store/selectors/blogSelectors';

const useMenuTabs = () => {
  const { BOOKING_TABLE } = useAppSelector(selectAppSettingsData);
  const blogPosts = useAppSelector(selectBlogPosts);

  const menuTabs = useMemo(() => {
    const tabs = [
      {
        label: 'home',
        path: '/',
      },
      {
        label: 'menu',
        path: '/menu',
      },
      {
        label: 'delivery',
        path: '/delivery',
      },
      {
        label: 'reserve',
        path: '/reserve',
      },
      {
        label: 'about',
        path: '/about',
      },
      {
        label: 'blog',
        path: '/blog',
      },
      {
        label: 'vacancies',
        path: '/vacancies',
      },
      {
        label: 'reviews',
        path: '/reviews',
      },
    ];

    return tabs
      .filter((tab) => {
        if (blogPosts.length <= 0) {
          return tab.label !== 'blog';
        }
        return tab;
      })
      .filter((tab) => {
        if (process.env.REACT_APP_IS_BOOKING_PAGE === 'false') {
          return tab.label !== 'reserve';
        }
        return tab;
      })
      .filter((tab) => {
        if (process.env.REACT_APP_IS_HOME_PAGE === 'false') {
          return tab.label !== 'home';
        }
        return tab;
      })
      .filter((tab) => {
        if (process.env.REACT_APP_IS_ABOUT_PAGE === 'false') {
          return tab.label !== 'about';
        }
        return tab;
      })
      .filter((tab) => {
        if (process.env.REACT_APP_IS_VACANCIES_PAGE === 'false') {
          return tab.label !== 'vacancies';
        }
        return tab;
      })
      .filter((tab) => {
        if (process.env.REACT_APP_IS_REVIEWS_PAGE === 'false') {
          return tab.label !== 'reviews';
        }
        return tab;
      })
      .map((tab) => {
        if (process.env.REACT_APP_IS_HOME_PAGE === 'false') {
          if (tab.label === 'menu') {
            return {
              label: 'menu',
              path: '/',
            };
          }
        }
        return tab;
      });
  }, [BOOKING_TABLE, blogPosts]);

  return menuTabs;
};

export default useMenuTabs;
