import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectApiKey } from 'store/selectors/accountSelectors';

interface FormValues {
  [x: string]: any;
}

const useAccountFormData = (formValues: FormValues) => {
  const apiKey = useAppSelector(selectApiKey);
  const { email, first_name, last_name, phone, birthday, sex } = formValues;

  const birthdayDate = useMemo(() => {
    const convertValue = new Date(birthday)
      .toLocaleString('fr-CA')
      .slice(0, 10);

    return convertValue;
  }, [formValues]);

  return {
    api_key: apiKey,
    body: {
      email,
      first_name,
      last_name,
      phone,
      birthday: birthdayDate,
      sex,
    },
  };
};

export default useAccountFormData;
