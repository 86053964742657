import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: process.env.REACT_APP_SUPPORT_LANGUAGES
      ? process.env.REACT_APP_SUPPORT_LANGUAGES.split(',')
      : ['en-IN'],
    load: 'currentOnly',
    fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
    debug: false,
    detection: {
      order: ['cookie'],
      caches: ['cookie'],
      // order: ['localStorage'],
      // caches: ['localStorage'],
    },
    backend: {
      loadPath: () => {
        return '/locales/{{lng}}/{{ns}}.json';
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
