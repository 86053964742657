import React, { FC } from 'react';
import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';

interface BannerProps {
  title: string;
  src: string;
}

const Banner: FC<BannerProps> = ({ title, src }) => {
  return (
    <Box
      sx={{
        display: { xs: 'none', sm: 'inline-flex' },
        width: '100%',
        position: 'relative',
        zIndex: '0',
        marginBottom: { xs: '20px', sm: '25px', md: '32px' },
        height: '60vh',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: '2',
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          padding: '15px 25px',
        }}
      >
        {!title ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            <Typography
              sx={{
                color: '#fff',
                fontSize: '78px',
                lineHeight: '80px',
                fontWeight: '400',
              }}
            >
              {title}
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                backgroundColor: 'primary.main',
                zIndex: '-1',
                opacity: '0.7',
              }}
            />
          </>
        )}
      </Box>
      {!src ? (
        <Skeleton
          sx={{ height: '60vh', width: '100%' }}
          animation="wave"
          variant="rectangular"
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          component="img"
          src={src}
        />
      )}
    </Box>
  );
};

export default Banner;
