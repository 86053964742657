import React, { FC, useEffect } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { SnackActions, TextField } from 'components/generic';
import { useForm } from 'react-hook-form';
import { Checkbox } from 'components/payment/Bonuses';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import { useDispatch } from 'react-redux';
import { calculateAvailablePoint } from 'store/reducers/AccountSlice/ActionCreators';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectBasketItemsForAvailableBonuses,
  selectTotalPrice,
} from 'store/selectors/orderSelectors';
import {
  selectAvailableBonus,
  selectApiKey,
} from 'store/selectors/accountSelectors';
import { useSnackbar } from 'notistack';

const Bonuses: FC = () => {
  const dispatch = useDispatch();
  const { control, watch, setValue } = useForm();
  const bonusesValue = watch('bonuses');
  const checkBoxBonusValue = watch('bonus');
  const { addPoints, clearBonuses } = orderSlice.actions;
  const { t } = useTranslation();
  const api_key = useAppSelector<string>(selectApiKey);
  const { totalPriceKop } = useAppSelector(selectTotalPrice);
  const availablePoints = useAppSelector<number>(selectAvailableBonus);
  const convertedBasketItems = useAppSelector(
    selectBasketItemsForAvailableBonuses
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleAddBonus = () => {
    if (bonusesValue > availablePoints) {
      enqueueSnackbar(t('bonuses_are_available_to_you', { availablePoints }), {
        action: SnackActions,
        variant: 'warning',
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
      setValue('bonuses', 0);
    } else {
      dispatch(addPoints(Number(bonusesValue * 100)));
    }
  };

  useEffect(() => {
    dispatch(
      calculateAvailablePoint({
        api_key,
        basket: convertedBasketItems,
        full_sum: totalPriceKop,
      })
    );
  }, [api_key, convertedBasketItems, totalPriceKop]);

  useEffect(() => {
    if (checkBoxBonusValue) {
      setValue('bonuses', availablePoints.toString());
    }
  }, [checkBoxBonusValue]);

  useEffect(() => {
    if (!checkBoxBonusValue) {
      dispatch(clearBonuses());
    }
  }, [checkBoxBonusValue]);

  return (
    <Box>
      <Checkbox control={control} />
      <Stack direction="row" spacing={0}>
        <TextField
          control={control}
          name="bonuses"
          label={t('number_of_bounses')}
          size="small"
          mask="9999999999"
          maskPlaceholder={null}
          required
          disabled={!checkBoxBonusValue}
        />
        <Button
          sx={{
            fontSize: { xs: '16px', sm: '18px' },
            lineHeight: { xs: '18px', sm: '21px' },
            textTransform: 'inherit',
            boxShadow: 'none',
            color: 'secondary.main',
            fontWeight: '300',
            width: '180px',
            '&:hover': {
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleAddBonus}
          disabled={!checkBoxBonusValue}
        >
          {t('apply')}
        </Button>
      </Stack>
    </Box>
  );
};

export default Bonuses;
