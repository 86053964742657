import React, { FC, useRef, useMemo } from 'react';
import {
  Box,
  Container,
  IconButton,
  Button,
  LinearProgress,
} from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectMenuIsLoading,
  selectMenuTabs,
} from 'store/selectors/menuSelectors';
import { useTranslation } from 'react-i18next';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import SwiperCore, { Autoplay, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import Parser from 'html-react-parser';

interface NavProps {
  activeCategory: number | string;
  handleScrollTo: (arg: string) => () => void;
}

const Nav: FC<NavProps> = ({ activeCategory, handleScrollTo }) => {
  SwiperCore.use([Autoplay, Mousewheel, Navigation]);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const { t, i18n } = useTranslation();
  const isLoading = useAppSelector(selectMenuIsLoading);
  const tabs = useAppSelector(selectMenuTabs(t('promo')));

  const isLtr = useMemo(() => {
    return i18n.dir() === 'ltr';
  }, [i18n.dir()]);

  const direction = i18n.dir() === 'ltr' ? 'ltr' : 'rtl';

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'common.white',
        position: 'fixed',
        top: { xs: '99px', md: '110px' },
        zIndex: '1100',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Container>
        <Box sx={{ position: 'relative' }}>
          <IconButton
            sx={{
              display: { xs: 'none', md: 'inline-flex' },
              marginRight: '10px',
              position: 'absolute',
              left: '-50px',
              top: '0',
              bottom: '0',
              height: '40px',
              margin: 'auto',
              '&.swiper-button-disabled': {
                opacity: 0,
                cursor: 'inherit',
              },
            }}
            size="medium"
            ref={navigationPrevRef}
          >
            {isLtr ? (
              <ArrowBackIosNew fontSize="medium" color="primary" />
            ) : (
              <ArrowForwardIos fontSize="medium" color="primary" />
            )}
          </IconButton>
          {isLoading ? (
            <Box
              sx={{
                width: '100%',
                height: '49px',
                position: 'relative',
              }}
            >
              <LinearProgress
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  height: '3px',
                  width: '100%',
                }}
                color="primary"
              />
            </Box>
          ) : (
            <Swiper
              dir={direction}
              freeMode
              slidesPerView="auto"
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
            >
              {tabs
                .sort((prev, next) => prev.orderBy - next.orderBy)
                .map((tab) => (
                  <SwiperSlide style={{ width: 'auto' }} key={tab.id}>
                    <Box
                      component={Button}
                      sx={{
                        color:
                          tab.id.toString() === activeCategory
                            ? 'primary.main'
                            : 'text.primary',
                        fontSize: '16px',
                        lineHeight: '19px',
                        fontWeight: '300',
                        textTransform: 'inherit',
                        transition: 'all 0.3s ease-in-out',
                        cursor: 'pointer',
                        padding: '15px 15px',
                        position: 'relative',
                        borderRadius: '0px',
                        '&:before': {
                          content: "''",
                          width: '100%',
                          height: '2px',
                          position: 'absolute',
                          backgroundColor: 'primary.main',
                          opacity:
                            tab.id.toString() === activeCategory ? '1' : '0',
                          transition: 'all 0.5s ease-in-out',
                          bottom: '0',
                          left: '0',
                        },
                      }}
                      key={tab.id}
                      onClick={handleScrollTo(tab.name)}
                    >
                      {Parser(tab.name)}
                    </Box>
                  </SwiperSlide>
                ))}
            </Swiper>
          )}

          <IconButton
            sx={{
              display: { xs: 'none', md: 'inline-flex' },
              marginLeft: '10px',
              position: 'absolute',
              right: '-50px',
              top: '0',
              bottom: '0',
              height: '40px',
              margin: 'auto',
              '&.swiper-button-disabled': {
                opacity: 0,
                cursor: 'inherit',
              },
            }}
            size="medium"
            ref={navigationNextRef}
          >
            {isLtr ? (
              <ArrowForwardIos fontSize="medium" color="primary" />
            ) : (
              <ArrowBackIosNew fontSize="medium" color="primary" />
            )}
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default Nav;
