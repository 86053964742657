import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import i18next from 'i18next';

const usePrice = (priceCop: number) => {
  const { CURRENCY_CODE } = useAppSelector(selectAppSettingsData);

  return Intl.NumberFormat(
    i18next.language || process.env.REACT_APP_DEFAULT_LANGUAGE,
    {
      style: 'currency',
      currency: `${CURRENCY_CODE}` || process.env.REACT_APP_CURRENCY_CODE,
    }
  ).format(priceCop / 100);
};

export default usePrice;
