import useRandomId from 'hooks/useRandomId';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { orderSlice } from 'store/reducers/OrderSlice/OrderSlice';
import {
  selectCardProductData,
  selectMenuCardPrice,
} from 'store/selectors/menuSelectors';
import {
  selectDeleteId,
  selectProductCount,
} from 'store/selectors/orderSelectors';

const useProductCard = (categoryId: number, productId: number) => {
  const dispatch = useAppDispatch();
  const { addProduct, addProductCount, removeProductCount, removeProduct } =
    orderSlice.actions;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    name,
    src,
    description,
    options,
    ingredients,
    cooking_options,
    group_modifiers,
    is_only_pickup,
    order_by,
  } = useAppSelector(selectCardProductData(productId, categoryId));
  const cardPriceCop = useAppSelector(
    selectMenuCardPrice(productId, categoryId)
  );
  const deleteId = useAppSelector(selectDeleteId(options[0].id)) as number;
  const productCount = useAppSelector(
    selectProductCount(options[0].id)
  ) as number;

  const randomId = useRandomId(order_by);

  const isCountButton = useMemo(() => {
    return (
      options.length <= 1 &&
      ingredients.length <= 0 &&
      cooking_options.length <= 0 &&
      group_modifiers.length <= 0
    );
  }, [options, ingredients, cooking_options, group_modifiers]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [isModalOpen]);

  const handleAddProductToBasket = useCallback(() => {
    if (isCountButton) {
      dispatch(
        addProduct({
          id: options[0].id,
          title: name,
          image: src,
          cooking_option_id: cooking_options[0]?.id || 0,
          total_price: options[0].price_kop,
          count: 1,
          group_modifiers,
          ingredients,
          description,
          is_only_pickup,
          deleteId: randomId,
        })
      );
    }
  }, [isCountButton]);

  const handleAddProductCount = useCallback(() => {
    dispatch(addProductCount(deleteId));
  }, [deleteId]);

  const handleRemoveProductCount = useCallback(() => {
    dispatch(removeProductCount(deleteId));
  }, [deleteId]);

  const isCount = useMemo(() => {
    return productCount > 0;
  }, [productCount]);

  useEffect(() => {
    if (productCount === 0) {
      dispatch(removeProduct(deleteId));
    }
  }, [productCount]);

  return {
    cardPriceCop,
    src,
    name,
    description,
    count: productCount,
    isCount,
    isModalOpen,
    isCountButton,
    handleOpenModal,
    handleCloseModal,
    handleAddProductToBasket,
    handleAddProductCount,
    handleRemoveProductCount,
  };
};

export default useProductCard;
