import React, { FC } from 'react';
import { Box, Fade } from '@mui/material';
import { TextArea } from 'components/generic';
import { useFormContext } from 'react-hook-form';
import {
  ClientForm,
  AddressForm,
  DeliveryTimeForm,
} from 'components/basket/BasketContent/Receiving';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAddressIsLoading } from 'store/selectors/addressSelectors';

interface DeliveryProps {
  handleAddressButton: () => void;
}

const Delivery: FC<DeliveryProps> = ({ handleAddressButton }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const isLoading = useAppSelector(selectAddressIsLoading);

  return (
    <Fade in timeout={400}>
      <Box sx={{ maxWidth: '620px' }}>
        <Box sx={{ marginBottom: { xs: '13px', sm: '18px' } }}>
          <ClientForm />
        </Box>
        <Box sx={{ marginBottom: { xs: '30px', sm: '50px' } }}>
          {!isLoading && (
            <AddressForm handleAddressButton={handleAddressButton} />
          )}
        </Box>
        <DeliveryTimeForm />
        <Box>
          <TextArea
            control={control}
            name="comment"
            label={t('comment_on_the_order')}
          />
        </Box>
      </Box>
    </Fade>
  );
};

export default Delivery;
