import React, { FC } from 'react';
import { Box, Typography, Link, Button } from '@mui/material';
import { MainTitle, TextField } from 'components/generic';
import { NavLink } from 'react-router-dom';
import { useStepOne } from 'hooks/auth';

interface StepOneProps {
  onClose: () => void;
  nextStep: () => void;
}

const StepOne: FC<StepOneProps> = ({ onClose, nextStep }) => {
  const {
    t,
    control,
    title,
    subTitle,
    inputLabel,
    pattern,
    mask,
    maskPlaceholder,
    codeIsLoading,
  } = useStepOne(nextStep);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box>
        <Box sx={{ marginBottom: '12px' }}>
          <MainTitle>{title}</MainTitle>
        </Box>
        <Typography
          sx={{
            marginBottom: '45px',
            fontSize: '18px',
            lineHeight: '21px',
          }}
        >
          {subTitle}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <TextField
          control={control}
          name="login"
          label={inputLabel}
          pattern={pattern}
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          required
          loading={codeIsLoading}
        />
      </Box>

      <Typography
        sx={{
          marginBottom: '20px',
          fontSize: '14px',
          lineHeight: '18px',
        }}
        variant="body2"
      >
        {t('auth.agreement_text_1')}{' '}
        <Link to="/terms_of_use" target="_blank" component={NavLink}>
          {t('auth.agreement_link_1')}
        </Link>{' '}
        {t('auth.agreement_text_2')}{' '}
        <Link to="/privacy_policy" target="_blank" component={NavLink}>
          {t('auth.agreement_link_2')}
        </Link>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
        gap={2}
      >
        <Button
          sx={{
            padding: '10px 0',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            fontWeight: '300',
            color: 'primary.main',
            boxShadow: 'none',
          }}
          variant="text"
          color="inherit"
          onClick={onClose}
          fullWidth
        >
          {t('later')}
        </Button>
        <Button
          sx={{
            padding: '15px 0',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            fontWeight: '300',
            color: 'primary.contrastText',
            boxShadow: 'none',
          }}
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
        >
          {t('continue')}
        </Button>
      </Box>
    </Box>
  );
};

export default StepOne;
