import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ButtonsProps {
  onClose: () => void;
}

const Buttons: FC<ButtonsProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Button
        sx={{
          textTransform: 'inherit',
          fontSize: '18px',
          fontWeight: '300',
          padding: '8px 25px',
          color: 'text.primary',
          boxShadow: 'none',
          borderRadius: '6px',
          border: '2px solid',
          borderColor: 'primary.main',
          '&:hover': {
            border: '2px solid',
            borderColor: 'primary.main',
          },
        }}
        variant="outlined"
        color="primary"
        onClick={onClose}
      >
        {t('cancel')}
      </Button>

      <Button
        sx={{
          textTransform: 'inherit',
          fontSize: '18px',
          fontWeight: '300',
          padding: '9px 25px',
          color: 'primary.contrastText',
          boxShadow: 'none',
          borderRadius: '6px',
        }}
        variant="contained"
        color="primary"
        type="submit"
      >
        {t('continue')}
      </Button>
    </Box>
  );
};

export default Buttons;
