import React, { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { TextField } from 'components/generic';
import { useStepTwo } from 'hooks/auth';

interface StepTwoProps {
  onClose: () => void;
  prevStep: () => void;
}

const StepTwo: FC<StepTwoProps> = ({ onClose, prevStep }) => {
  const {
    t,
    control,
    verifyIsLoading,
    isTimer,
    timer,
    hint,
    handleGetCode,
    loginValue,
    handleLogIn,
    inputCodeMask,
  } = useStepTwo(onClose);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box>
        <Typography sx={{ marginBottom: '12px' }} variant="h4">
          {t('confirmation_code')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '45px',
            lineHeight: '21px',
          }}
        >
          {hint} {loginValue}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: '30px' }}>
        <TextField
          control={control}
          name="code"
          label={t('input_code')}
          mask="9999"
          maskPlaceholder={null}
          required
          loading={verifyIsLoading}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        {isTimer ? (
          <Typography sx={{ fontSize: '13px', color: 'grey.700' }}>
            {t('try_to_send_again', { timer })}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: '13px',
              lineHeight: '16px',
              color: 'secondary.main',
              cursor: 'pointer',
              alignSelf: 'flex-start',
            }}
            onClick={handleGetCode}
          >
            {t('send_again')}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
        gap={2}
      >
        <Button
          sx={{
            padding: '10px 0',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            fontWeight: '300',
            color: 'text.primary',
            boxShadow: 'none',
          }}
          variant="text"
          color="inherit"
          onClick={prevStep}
          fullWidth
        >
          {t('back')}
        </Button>

        <Button
          sx={{
            padding: '15px 0',
            textTransform: 'inherit',
            fontSize: '18px',
            lineHeight: '21px',
            borderRadius: '6px',
            fontWeight: '300',
            color: 'primary.contrastText',
            boxShadow: 'none',
          }}
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          onClick={handleLogIn}
        >
          {t('continue')}
        </Button>
      </Box>
    </Box>
  );
};

export default StepTwo;
