import { useState, useEffect } from 'react';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

const usePriceConverter = (modifierName: string, modifierPrice?: number) => {
  const [labelWithPrice, setLabelWithPrice] = useState<string>('');
  const { CURRENCY_SYMBOL } = useAppSelector(selectAppSettingsData);

  useEffect(() => {
    if (modifierPrice) {
      setLabelWithPrice(
        `${modifierName} +${CURRENCY_SYMBOL} ${modifierPrice / 100}`
      );
    } else {
      setLabelWithPrice(modifierName);
    }
  }, [modifierName, modifierPrice]);

  return [labelWithPrice];
};

export default usePriceConverter;
