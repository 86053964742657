import { useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useTimer } from 'hooks';
import { useAuth } from 'hooks/auth';
import { verifyCode } from 'store/reducers/AccountSlice/ActionCreators';
import { accountSlice } from 'store/reducers/AccountSlice/AccountSlice';
import {
  selectCodeData,
  selectVerifyData,
  selectVerifyIsLoading,
} from 'store/selectors/accountSelectors';

const useStepTwo = (onClose: () => void) => {
  const dispatch = useAppDispatch();
  const { addApiKey } = accountSlice.actions;
  const { control, watch, setError, clearErrors } = useFormContext();
  const loginValue = watch('login');
  const codeValue = watch('code');
  const verifyIsLoading = useAppSelector(selectVerifyIsLoading);
  const { AUTH_SERVICE_TYPE } = useAppSelector(selectAppSettingsData);
  const { t } = useTranslation();
  const { isTimer, timer, setTimer } = useTimer(30);
  const { getAuthCode } = useAuth();
  const { success, api_key } = useAppSelector(selectVerifyData);
  const { uid } = useAppSelector(selectCodeData);

  const logIn = (code: string, uidCode: string) => {
    dispatch(verifyCode({ uid: uidCode, code }))
      .unwrap()
      .then((result) => {
        if (!result.success && result.reason === 'WRONG_AUTH_CODE') {
          setError('code', {
            type: 'server',
            message: t('invalid_code'),
          });
        }
        if (result.success && result.api_key) {
          dispatch(addApiKey(result.api_key));
        }
      });
  };

  const hint = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 1) {
      return t('auth_tip_4');
    }
    return t('auth_tip_3');
  }, [AUTH_SERVICE_TYPE]);

  const inputCodeMask = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 0) {
      return '9999';
    }
    return '9999';
  }, [AUTH_SERVICE_TYPE]);

  const handleGetCode = () => {
    if (AUTH_SERVICE_TYPE === 1) {
      getAuthCode(loginValue.slice(1), AUTH_SERVICE_TYPE);
    }
    if (AUTH_SERVICE_TYPE === 0) {
      getAuthCode(loginValue, AUTH_SERVICE_TYPE);
    }
    setTimer(30);
  };

  useEffect(() => {
    setTimer(30);
  }, []);

  useEffect(() => {
    if (codeValue && codeValue.length >= 4) {
      logIn(codeValue, uid);
    }
    if (!codeValue || codeValue.length < 4) {
      clearErrors();
    }
  }, [codeValue, uid]);

  useEffect(() => {
    if (api_key && success) {
      onClose();
    }
  }, [api_key, success]);

  const handleLogIn = () => {
    logIn(codeValue, uid);
  };

  return {
    t,
    control,
    verifyIsLoading,
    isTimer,
    timer,
    hint,
    handleGetCode,
    loginValue,
    handleLogIn,
    inputCodeMask,
  };
};

export default useStepTwo;
