import React, { FC } from 'react';
import {
  Box,
  Typography,
  Stack,
  Card as MUICard,
  CardActionArea,
} from '@mui/material';
import { brandSlice } from 'store/reducers/BrandSlice/BrandSlice';
import { useAppDispatch } from 'store/hooks/useAppSelector';

interface CardProps {
  id: number;
  name: string;
  description: string;
  logo: string;
  image: string;
  onClose: () => void;
}

const Card: FC<CardProps> = ({
  id,
  name,
  description,
  logo,
  image,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { selectBrand } = brandSlice.actions;

  const handleSelectBrand = () => {
    dispatch(selectBrand(id));
    onClose();
  };

  return (
    <MUICard
      sx={{
        cursor: 'pointer',
        borderRadius: '10px',
        boxShadow: 'none',
      }}
      onClick={handleSelectBrand}
    >
      <CardActionArea sx={{ padding: '15px' }}>
        <Box
          sx={{
            height: '150px',
            minWidth: '350px',
            overflow: 'hidden',
            position: 'relative',
            borderRadius: '10px',
            marginBottom: '5px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '160px',
              height: '100px',
              transform: 'translate(-50%, -50%)',
            }}
            alt="logo"
            src={logo}
            component="img"
          />
          <Box
            component="img"
            sx={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '100%',
              height: '100%',
              minHeight: '100%',
            }}
            alt="image"
            src={image}
          />
        </Box>

        <Stack direction="column" spacing={0.5}>
          <Typography
            sx={{ fontSize: '20px', lineHeight: '23px', fontWeight: '600' }}
          >
            {name}
          </Typography>
          <Typography
            sx={{ fontSize: '14px', lineHeight: '16px', fontWeight: '300' }}
          >
            {description}
          </Typography>
        </Stack>
      </CardActionArea>
    </MUICard>
  );
};

export default Card;
