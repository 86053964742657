import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Account, AccountArgs } from 'types/Account';
import {
  CheckVerifyCode,
  GetCodeParams,
  ResponseAuthCode,
  ResponseVerifyCode,
} from 'types/Auth';

export const getAccount = createAsyncThunk(
  'account/get_account',
  async (api_key: string, thunkAPI) => {
    try {
      const response = await axios.get<Account>(
        `${process.env.REACT_APP_V3}/account/`,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get account data, ${e}`);
    }
  }
);

export const updateAccountData = createAsyncThunk(
  'account/update_account',
  async (data: AccountArgs, thunkAPI) => {
    const { api_key, body } = data;
    try {
      const response = await axios.patch<Account>(
        `${process.env.REACT_APP_V3}/account/`,
        body,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to update account data, ${e}`);
    }
  }
);

export const calculateAvailablePoint = createAsyncThunk(
  'account/calculate_available_point',
  async (data: any, thunkAPI) => {
    const { api_key, basket, full_sum } = data;

    const params = new FormData();
    params.append('api_key', api_key);
    params.append('full_sum', full_sum);
    params.append('basket', JSON.stringify(basket));

    try {
      const response = await axios.post<any>(
        `${process.env.REACT_APP_V2}/account/calculate_available_point/`,
        params
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Couldn't calculate available points, ${e}`
      );
    }
  }
);

export const postFeedback = createAsyncThunk(
  'account/post_feedback',
  async (data: { api_key: string; body: any }, thunkAPI) => {
    const { api_key, body } = data;
    try {
      const response = await axios.post<any>(
        `${process.env.REACT_APP_V1}/account/rate_feedback/`,
        body,
        {
          params: {
            api_key,
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Couldn't calculate available points, ${e}`
      );
    }
  }
);

export const getCode = createAsyncThunk(
  'account/send_auth_code',
  async (params: GetCodeParams, thunkAPI) => {
    const { login, service_type } = params;
    try {
      const response = await axios.post<ResponseAuthCode>(
        `${process.env.REACT_APP_V3}/account/send_auth_code/`,
        {
          service_type,
          login,
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Can't send the auth code. ${e}`);
    }
  }
);

export const verifyCode = createAsyncThunk(
  'account/verify_auth_code',
  async (params: CheckVerifyCode, thunkAPI) => {
    const { uid, code } = params;
    try {
      const response = await axios.post<ResponseVerifyCode>(
        `${process.env.REACT_APP_V3}/account/verify_auth_code/`,
        {
          uid,
          code,
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(`Can't verify the auth code. ${e}`);
    }
  }
);
