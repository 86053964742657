import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectCities = (state: RootState) => state.cities;

/**
 * Returns cities
 */
export const selectCitiesData = createSelector(
  [selectCities],
  (cities) => cities.cities
);
/**
 * Returns selected city id
 */
export const selectCityId = createSelector(
  [selectCities],
  (cities) => cities.city_id
);
/**
 * Returns cities is loading
 */
export const selectCitiesIsLoading = createSelector(
  [selectCities],
  (cities) => cities.isLoading
);

/**
 * Select city data for the select field
 */
export const selectCitiesList = createSelector([selectCities], (cities) =>
  cities.cities.map((city) => {
    return { label: city.name, value: city.id };
  })
);
/**
 * Select the data of the selected location city
 */
export const selectLocationCity = createSelector(
  [selectCities, selectCityId],
  (cities, cityId) => cities.cities.filter((city) => city.id === cityId)[0]
);
/**
 * Select a city name by id
 */
export const selectCityNameById = (id: string | number | undefined) =>
  createSelector([selectCitiesData], (citiesData) => {
    const selectedCity = citiesData.find(
      (city) => city.id === Number(id)
    )?.name;
    return selectedCity || '';
  });
