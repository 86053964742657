import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectSocialMedia } from 'store/selectors/socialSelectors';
import SocialLink from './SocialLink/SocialLink';

interface SocialLinkProps {
  color?:
    | 'primary'
    | 'secondary'
    | 'inherit'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

const SocialLinks: FC<SocialLinkProps> = ({ color }) => {
  const socialMedia = useAppSelector(selectSocialMedia);

  return (
    <Box sx={{ display: 'flex' }}>
      {socialMedia.map((socialMediaItem) => (
        <SocialLink
          key={socialMediaItem.id}
          link={socialMediaItem.link}
          name={socialMediaItem.name}
          color={color}
        />
      ))}
    </Box>
  );
};

export default SocialLinks;
