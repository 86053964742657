import { createTheme } from '@mui/material';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectAppSettingsData } from 'store/selectors/appSelectors';

const useStyles = () => {
  const {
    COLOR_PRIMARY,
    COLOR_SECONDARY,
    COLOR_TEXT_ON_PRIMARY_COLOR,
    COLOR_TEXT_ON_SECONDARY_COLOR,
  } = useAppSelector<{
    COLOR_PRIMARY: string;
    COLOR_SECONDARY: string;
    COLOR_TEXT_ON_PRIMARY_COLOR: string;
    COLOR_TEXT_ON_SECONDARY_COLOR: string;
  }>(selectAppSettingsData);
  // test
  // ToDo: currently -apple-system font is using across the project
  // We should add correct fonts (provided in assets folder)
  // Example of hot to import different typography variants:
  // https://github.com/mui/material-ui/issues/14264
  const theme = createTheme({
    direction: 'ltr',
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Montserrat"',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },

    palette: {
      mode: 'light',
      primary: {
        main: COLOR_PRIMARY || '#FFFFFF',
        contrastText: COLOR_TEXT_ON_PRIMARY_COLOR || '#FFFFFF',
      },
      secondary: {
        main: COLOR_SECONDARY || '#E5E5E5',
        contrastText: COLOR_TEXT_ON_SECONDARY_COLOR || '#FFFFFF',
      },
      text: {
        primary: process.env.REACT_APP_TEXT_DARK,
      },
      error: {
        main: '#EB5757',
      },
      grey: {
        50: '#444444',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            lineHeight: '0',
            // '-webkit-tap-highlight-color': 'transparent',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
    },
  });

  return theme;
};

export default useStyles;
