import React, { FC } from 'react';
import { CardMedia, Box } from '@mui/material';
import { useSrcImage } from 'hooks';

export interface MenuCardImageProps {
  src: string;
  alt?: string;
}
const MenuCardImage: FC<MenuCardImageProps> = ({ src, alt }) => {
  const image = useSrcImage(src);
  return (
    <>
      <Box sx={{ overflow: 'hidden', position: 'relative' }}>
        <CardMedia
          sx={{
            height: { xs: '167px', sm: '200px', md: '275px' },
            backgroundColor: '#F2F2F2',
            transition: '0.5s',
            '&:hover': {
              transform: { xs: 'none', md: 'scale(1.2)' },
            },
          }}
          component="img"
          image={image}
          alt={alt}
        />
      </Box>
    </>
  );
};

export default MenuCardImage;
