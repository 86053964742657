import { useEffect, useMemo } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { selectAppSettingsData } from 'store/selectors/appSelectors';
import { useFormContext } from 'react-hook-form';
import {
  selectCodeData,
  selectCodeIsLoading,
} from 'store/selectors/accountSelectors';
import { AppSettingsData } from 'types/AppSettings';

const useStepOne = (nextStep: () => void) => {
  const { control, setValue } = useFormContext();
  const { success } = useAppSelector(selectCodeData);
  const codeIsLoading = useAppSelector(selectCodeIsLoading);
  const { AUTH_SERVICE_TYPE } = useAppSelector<AppSettingsData>(
    selectAppSettingsData
  );
  const { t } = useTranslation();
  const phoneValue = sessionStorage.getItem('phoneValue');

  useEffect(() => {
    if (success) {
      nextStep();
    }
  }, [success]);

  const title = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 1) {
      return t('sign_up_with_phone');
    }
    return t('sign_up_with_email');
  }, [AUTH_SERVICE_TYPE]);

  const subTitle = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 1) {
      return t('auth_tip_2');
    }
    return t('auth_tip_1');
  }, [AUTH_SERVICE_TYPE]);

  const inputLabel = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 1) {
      return t('phone_number');
    }
    return t('email');
  }, [AUTH_SERVICE_TYPE]);

  const pattern = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 1) {
      return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
    }
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  }, [AUTH_SERVICE_TYPE]);

  const mask = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 1) {
      return '+79999999999';
    }
    return '';
  }, [AUTH_SERVICE_TYPE]);

  const maskPlaceholder = useMemo(() => {
    if (AUTH_SERVICE_TYPE === 1) {
      return '';
    }
    return '';
  }, [AUTH_SERVICE_TYPE]);

  useEffect(() => {
    if (AUTH_SERVICE_TYPE === 1) {
      setValue('login', phoneValue);
    }
  }, [phoneValue, AUTH_SERVICE_TYPE]);

  return {
    t,
    control,
    title,
    subTitle,
    inputLabel,
    pattern,
    mask,
    maskPlaceholder,
    codeIsLoading,
  };
};

export default useStepOne;
