import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector';
import { getAccount } from 'store/reducers/AccountSlice/ActionCreators';
import { getAddresses } from 'store/reducers/AddressSlice/ActionCreators';
import {
  getAppSettings,
  getServerTime,
} from 'store/reducers/AppSettingsSlice/ActionCreators';
import { getCities } from 'store/reducers/CitySlice/ActionCreators';
import { getMenu } from 'store/reducers/MenuSlice/ActionCreators';
import { getPages } from 'store/reducers/PagesSlice/ActionCreators';
import { getPromotions } from 'store/reducers/PromotionSlice/ActionCreators';
import { getRestaurantList } from 'store/reducers/RestaurantSlice/ActionCreators';
import { getSocialMedia } from 'store/reducers/SocialMediaSlice/ActionCreators';
import { useTranslation } from 'react-i18next';
import { getBlogPosts } from 'store/reducers/BlogSlice/ActionCreators';
import { useAuth } from 'hooks/auth';
import { selectApiKey } from 'store/selectors/accountSelectors';
import { getBrands } from 'store/reducers/BrandSlice/ActionCreators';
import { selectBrandId, selectBrands } from 'store/selectors/brandsSelectors';
import { selectCityId } from 'store/selectors/citySelectors';
import { brandSlice } from 'store/reducers/BrandSlice/BrandSlice';
import { Brand } from 'types/Brands';
import { calculateBasket } from 'store/reducers/OrderSlice/ActionCreators';
import {
  selectBasketDataForRequest,
  selectIsDelivery,
  selectOrderPromotions,
} from 'store/selectors/orderSelectors';
import { selectAddressForBasket } from 'store/selectors/addressSelectors';

const useInitApp = () => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  const { i18n } = useTranslation();
  const lang = useMemo(() => {
    return i18n.language.slice(0, 2);
  }, [i18n.language]);
  const { selectBrand } = brandSlice.actions;
  const api_key = useAppSelector(selectApiKey);
  const cityId = useAppSelector(selectCityId);
  const brandId = useAppSelector(selectBrandId);
  const brands = useAppSelector<Brand[]>(selectBrands);
  const basketData = useAppSelector(selectBasketDataForRequest);
  const selectedAddressData = useAppSelector(selectAddressForBasket);
  const isDelivery = useAppSelector(selectIsDelivery);
  const promotions = useAppSelector(selectOrderPromotions);

  useEffect(() => {
    dispatch(
      calculateBasket({
        api_key,
        platform: 2,
        brand_id: 0,
        body: {
          basket: basketData,
          is_delivery: isDelivery,
          address: selectedAddressData,
          actions: promotions,
        },
      })
    );
  }, [isDelivery, selectedAddressData, basketData, promotions]);

  useEffect(() => {
    dispatch(getBrands(lang));
  }, []);

  useEffect(() => {
    if (brands.length > 0) {
      dispatch(selectBrand(brands[0].id));
    }
  }, [brands]);

  useEffect(() => {
    dispatch(getAppSettings(lang));

    // dispatch(getCities({ lang, brand: brandId || undefined }));
    dispatch(
      getCities({
        lang,
        brand: Number(process.env.REACT_APP_BRAND) || undefined,
      })
    );

    // dispatch(getRestaurantList({ lang, brand: brandId || undefined }));
    dispatch(
      getRestaurantList({
        lang,
        brand: Number(process.env.REACT_APP_BRAND) || undefined,
      })
    );
    dispatch(getServerTime());
    dispatch(getPages(lang));
    dispatch(getSocialMedia());
    dispatch(getBlogPosts(lang));
  }, [lang, cityId, brandId]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getAccount(api_key));
      dispatch(getAddresses(api_key));
    }
  }, [api_key, isAuth]);

  useEffect(() => {
    dispatch(
      getMenu({
        lang,
        city: cityId || undefined,
        // brand: brandId || undefined,
        brand: Number(process.env.REACT_APP_BRAND) || undefined,
      })
    );
    dispatch(
      getPromotions({
        api_key,
        lang,
        platform: 2,
        city_id: cityId || undefined,
        // brand_id: brandId || undefined,
        brand_id: Number(process.env.REACT_APP_BRAND) || undefined,
      })
    );
  }, [cityId, brandId]);

  // useEffect(() => {
  //   if (brandId) {
  //     dispatch(getCities({ lang, brand: brandId || undefined }));
  //   }
  //   if (brands.length < 0)
  // }, [brandId]);
};

export default useInitApp;
