import React, { FC } from 'react';
import { Typography, Box } from '@mui/material';

interface InfoProps {
  productName: string;
  description: string;
}

const Info: FC<InfoProps> = ({ productName, description }) => {
  return (
    <>
      <Box
        sx={{
          minHeight: { xs: '0px', md: '90px', lg: '125px' },
          flexGrow: '1',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '20px' },
            lineHeight: { xs: '19px', sm: '23px' },
            fontWeight: '400',
            color: 'text.primary',
            marginBottom: '6px',
            textAlign: { xs: 'center', sm: 'left' },
          }}
          variant="h5"
        >
          {productName}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '300',
            display: { xs: 'none', sm: 'block' },
            color: 'text.primary',
          }}
          variant="body2"
          gutterBottom
        >
          {description}
        </Typography>
      </Box>
    </>
  );
};

export default Info;
