import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectBlog = (state: RootState) => state.blog;

/**
 * Returns the blogPosts
 */
export const selectBlogPosts = createSelector(
  [selectBlog],
  (blog) => blog.blogPosts
);
/**
 * Returns blog is loading
 */
export const selectBlogIsLoading = createSelector(
  [selectBlog],
  (blog) => blog.isLoading
);
