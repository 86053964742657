import React, { FC, useMemo } from 'react';
import { CssBaseline, Box, Fab } from '@mui/material';
import {
  Footer,
  Header,
  CookiesBanner,
  ScrollToTopButton,
} from 'components/generic';
import { Outlet, useLocation } from 'react-router-dom';
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';

const AppLayout: FC = () => {
  const location = useLocation();

  const isHeader = useMemo(() => {
    switch (location.pathname) {
      case '/cart':
        return false;
      case '/payment':
        return false;
      default:
        return true;
    }
  }, [location.pathname]);

  const isFooter = useMemo(() => {
    switch (location.pathname) {
      case '/cart':
        return false;
      case '/payment':
        return false;
      default:
        return true;
    }
  }, [location.pathname]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Box id="back-to-top-anchor" />
        {isHeader && <Header />}
        <Box
          sx={{
            paddingTop:
              isFooter || isHeader ? { xs: '100px', md: '110px' } : '0px',
            flexGrow: '1',
            lineHeight: '1',
          }}
        >
          <Outlet />
          <ScrollToTopButton>
            <Fab color="primary" size="large" aria-label="scroll back to top">
              <KeyboardArrowUpIcon sx={{ color: 'primary.contrastText' }} />
            </Fab>
          </ScrollToTopButton>
        </Box>
        {isFooter && <Footer />}
      </Box>
      <CookiesBanner />
    </>
  );
};

export default AppLayout;
