import React, { FC } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { BackDrawerButton, TextField } from 'components/generic';
import { useStepTwo } from 'hooks/auth';

interface StepTwoProps {
  prevStep: () => void;
  onClose: () => void;
}

const StepTwo: FC<StepTwoProps> = ({ prevStep, onClose }) => {
  const {
    t,
    control,
    verifyIsLoading,
    isTimer,
    timer,
    hint,
    handleGetCode,
    loginValue,
    handleLogIn,
  } = useStepTwo(onClose);

  return (
    <Box sx={{ padding: '100px 20px 20px 20px' }}>
      <Box
        sx={{ position: 'fixed', top: '24px', left: '20px', zIndex: '100' }}
        onClick={prevStep}
      >
        <BackDrawerButton direction="down" />
      </Box>
      <Box>
        <Typography sx={{ marginBottom: '11px' }} variant="h4">
          {t('confirmation_code')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '17px',
            lineHeight: '21px',
          }}
        >
          {hint} {loginValue}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: '50px' }}>
        <TextField
          control={control}
          name="code"
          label={t('input_code')}
          mask="9999"
          maskPlaceholder={null}
          required
          loading={verifyIsLoading}
        />
      </Box>
      <Box sx={{ marginBottom: '25px' }}>
        {isTimer ? (
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '18px',
              color: 'secondary.main',
            }}
          >
            {t('try_to_send_again', { timer })}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '18px',
              color: 'secondary.main',
              alignSelf: 'flex-start',
            }}
            onClick={handleGetCode}
          >
            {t('send_again')}
          </Typography>
        )}
      </Box>

      <Button
        sx={{
          padding: '15px 0',
          textTransform: 'inherit',
          fontSize: '18px',
          lineHeight: '21px',
          borderRadius: '6px',
          boxShadow: 'none',
          fontWeight: '300',
          color: 'primary.contrastText',
        }}
        variant="contained"
        color="primary"
        fullWidth
        type="submit"
        onClick={handleLogIn}
      >
        {t('continue')}
      </Button>
    </Box>
  );
};

export default StepTwo;
