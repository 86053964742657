import React, { FC, memo } from 'react';
import { Box, Card as MUICard, Skeleton } from '@mui/material';
import { Image, Info, Footer } from 'components/menu/Card';
import { useProductCard } from 'hooks/menu';
import { ProductModal } from 'components/modals';

interface CardProps {
  categoryId: number;
  productId: number;
  isIntersecting: boolean;
}

const Card: FC<CardProps> = ({ categoryId, productId, isIntersecting }) => {
  const {
    cardPriceCop,
    src,
    name,
    description,
    count,
    isCount,
    isModalOpen,
    isCountButton,
    handleOpenModal,
    handleCloseModal,
    handleAddProductToBasket,
    handleAddProductCount,
    handleRemoveProductCount,
  } = useProductCard(categoryId, productId);

  return (
    <>
      <MUICard
        sx={{
          borderRadius: '0px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          textDecoration: 'none',
          boxShadow: 'none',
          WebkitTapHighlightColor: 'transparent',
          cursor: 'pointer',
        }}
        onClick={handleOpenModal}
      >
        {isIntersecting ? (
          <Image src={src} alt="product" />
        ) : (
          <Skeleton
            sx={{
              height: { xs: '150px', sm: '167px', md: '275px' },
            }}
            animation="wave"
            variant="rectangular"
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            padding: { xs: '12px', sm: '20px' },
          }}
        >
          <Info productName={name} description={description} />
          <Footer
            count={count}
            isCount={isCount}
            priceCop={cardPriceCop}
            isCountModeButton={isCountButton}
            handleAddProductToBasket={handleAddProductToBasket}
            handleAddProductCount={handleAddProductCount}
            handleRemoveProductCount={handleRemoveProductCount}
          />
        </Box>
      </MUICard>
      <ProductModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        productId={productId}
        categoryId={categoryId}
      />
    </>
  );
};

export default memo(Card);
