import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppSettingsData } from 'types/AppSettings';
import { ServerTimeResponse } from 'types/ServerTime';

export const getAppSettings = createAsyncThunk(
  'app/get_app_settings',
  async (lang: string, thunkAPI) => {
    try {
      const response = await axios.get<AppSettingsData>(
        `${process.env.REACT_APP_V3}/delivery_app_settings/`,
        {
          headers: {
            'Accept-Language': lang,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getServerTime = createAsyncThunk(
  'app/get_server_time',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<ServerTimeResponse>(
        `${process.env.REACT_APP_V2}/server_time/`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        `Не удалось получить серверное время с ошибкой ${e}`
      );
    }
  }
);
